import { get } from 'lodash'

export const baseURL = process.env.VUE_APP_API_URL

export const AlertMessage = (message = '', status = '', type = '') => {
  return {
    message,
    status,
    type,
  }
}

export const errorMessage = (e) => {
  const errorMessage = e
  // console.log(errorMessage);
  switch (get(errorMessage, 'response.status')) {
    case 400: {
      const message = get(errorMessage, 'response.data')
      return message
    }
    case 401: {
      const message =
        get(errorMessage, 'response.data.message') ||
        get(errorMessage, 'response.data')
      if (message === 'invalid or expired jwt') {
        localStorage.removeItem('token')
        location.reload()
        return message
      } else {
        return message
      }
    }
    case 405: {
      const message =
        get(errorMessage, 'response.data.message') ||
        get(errorMessage, 'response.data')
      return message
    }
    case 422: {
      const message =
        get(errorMessage, 'response.data.message') ||
        get(errorMessage, 'response.data')
      return message
    }
    default:
      break
  }
  return
}

export const itemsPerPageOptions = [
  25,
  50,
  100,
  250,
  { text: 'All', value: -1 },
]

export const jsonToForm = (json = {}) => {
  const formData = new FormData()
  const arr = Object.keys(json)
  const len = arr.length

  for (let i = 0; i < len; i++) {
    const key = arr[i]
    const value = json[key]
    if (value) {
      formData.append(key, value)
    }
  }

  return formData
}
