<template>
  <div>
    <div v-if="dataList != undefined && dataList.length > 0">
      <v-data-table
        class="mx-0 mx-md-4"
        :loading="isLoading"
        :headers="headers"
        :page.sync="currentPage"
        :items-per-page="perPage"
        :items="dataList"
        hide-default-footer
        no-data-text="ไม่พบข้อมูล"
        no-results-text="ไม่พบข้อมูล"
      >
        <template v-slot:item.no="{ item }">
          <div>{{ item.no }}</div>
        </template>
        <template v-slot:item.fileName="{ item }">
          <div>{{ item.fileName }}</div>
        </template>
        <template v-slot:item.dateReject="{ item }">
          <div>{{ item.dateReject }}</div>
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn icon @click="onViewDetail(item)">
            <v-icon>mdi-file-document-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>

      <Paginations
        :page="currentPage"
        :pageCount="pageCount"
        :total="total"
        :itemsPerPage="perPage"
        :itemsPerPageOptions="itemsPerPageOptions"
        :onChangePage="onChangePage"
      />
    </div>
    <div class="d-flex justify-center" v-else>
      <h3>ไม่พบข้อมูล</h3>
    </div>
  </div>
</template>

<script>
import Paginations from "@/components/Paginations";
import { itemsPerPageOptions } from "@/plugins/common";
import Utils from "@/mixin";
export default {
  mixins: [Utils],
  props: {
    dataList: {
      type: Array,
      default: () => [{ data: [] }],
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 50,
    },
    pageCount: {
      type: Number,
      default: 1,
    },

    onChangePage: {
      type: Function,
      default: () => {},
    },
    onViewDetail: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    Paginations,
  },
  data: () => ({
    itemsPerPageOptions: [],
    headers: [
      { text: "No", value: "no", sortable: false, dataType: "number" },
      { text: "ชื่อเอกสาร", value: "fileName", sortable: false },
      { text: "หมายเหตุ", value: "remark", sortable: false },
      {
        text: "Date Rejected",
        value: "createdAt",
        sortable: false,
      },
      { text: "Action", value: "action", sortable: false, align: "center" },
    ],
  }),

  methods: {
    deleteItem(data) {
      console.log(data);
    },
  },
  // computed: {
  //   tablePagesCount: {
  //     get() {
  //       return this.pageCount;
  //     },
  //     set(value) {
  //       this.pageCount = value;
  //     },
  //   },
  // },
  mounted() {
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
};
</script>
