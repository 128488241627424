<template>
  <div :class="` align-self-center text-status px-3 mr-5 ${variant}`">
    {{ status }}
  </div>
</template>
<script>
export default {
  name: "Text-Starus",
  props: {
    status: { type: String, default: "" },
    variant: { type: String, default: "" },
  },
};
</script>
<style lang="scss">
$gray: #d9d9d9;
$green: #79a341;
$light-red: #f7d1cd;
$red: #ff0000;
.text-status {
  font-weight: bold;
  border-radius: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 2px;
  padding-right: 10px;
  text-align: center;
}
.accept {
  background: $gray;
  color: $green;
}
.reject {
  background: $light-red;
  color: $red;
}
</style>
