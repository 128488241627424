<template>
  <div class="login-container">
    <v-toolbar flat height="90" class="login-header-wrapper">
      <v-spacer />
      <router-link :to="'/'">
        <v-img class="home-logo" :src="require('@/assets/logo.svg')" contain />
      </router-link>
      <v-spacer />
    </v-toolbar>
    <h1 class="login-title">TBR CMS</h1>
    <div class="login-wrapper">
      <v-row class="justify-center">
        <v-col cols="10" sm="8" md="4" lg="3">
          <v-card elevation="0">
            <v-form
              :disabled="isLoading"
              v-model="valid"
              :lazy-validation="false"
              @submit.prevent="submitForm"
            >
              <v-text-field
                label="Username"
                id="email"
                outlined
                dense
                color="black"
                v-model="email"
                :rules="[rules.required]"
              />
              <v-text-field
                label="Password"
                id="password"
                outlined
                dense
                color="black"
                :type="show ? 'text' : 'password'"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                :rules="[rules.required]"
                v-model="password"
              />
              <v-btn
                id="login-button"
                large
                block
                color="primary"
                type="submit"
                :dark="valid"
                :disabled="isLoading || !valid"
                >Log in</v-btn
              >
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Utils from '@/mixin'

export default {
  mixins: [Utils],
  data: () => ({
    valid: false,
    show: false,
    email: '',
    password: '',
    rules: {
      required: (value) => !!value || 'Require field.',
      emailValidate: (email) => {
        const re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
        if (re.test(String(email).toLowerCase())) {
          return true
        }
        return 'Invalid format.'
      },
    },
  }),
  methods: {
    async submitForm() {
      const { email, password } = this
      const formData = {
        email,
        password,
      }
      this.login(formData)
    },
  },
}
</script>

<style lang="scss" scoped>
$black: rgba(0, 0, 0, 0.2);
$white: rgba(255, 255, 255, 1);

.login-container {
  // background: url('../assets/bg.svg');
  // background-size: cover;
  background: $white;
  text-align: center;
  height: 100vh;
  position: relative;
  .login-header-wrapper {
    border-bottom: 1px solid $black;
    position: relative;
    .login-header-button {
      display: none;
      @media (min-width: 768px) {
        display: block;
        position: absolute;
        right: 2%;
      }
    }
  }
  .login-title {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
  }
  .login-wrapper {
    display: flex;
    align-items: center;
    height: calc(100vh - 90px);
  }
  .forgot-password-button {
    button {
      padding: 0;
    }
  }
}
</style>
