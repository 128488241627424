import { get } from "lodash";
import { REQUEST_POST } from "./API_Request";
import { AlertMessage, errorMessage, jsonToForm } from "@/plugins/common";
import { GLOBAL_MESSAGE } from "@/plugins/message";

export const state = {
  points: {
    data: [],
    point_upload_id: null,
    total: 0,
    error: "",
  },
};

export const mutations = {
  async SAVE_POINTS(state, data) {
    data.toggleLoading();
    try {
      let body = {
        result: state.points.data,
      };
      const res = await REQUEST_POST("/api/point-transactions", body);

      if (get(res, "status") === 201) {
        if (res?.data?.ok) {
          state.points.data = [];
          state.points.total = 0;
          data.toggleLoading();
          const alert = new AlertMessage(
            GLOBAL_MESSAGE.UPLOAD.SUCCESS,
            "ok",
            "success"
          );
          data.toggleAlert(alert);
        } else {
          
          state.points.error = res.data?.error;
          data.toggleLoading();

          let alert = new AlertMessage(res.data?.error, "error", "error");
          data.toggleAlert(alert);
        }
      }
      if (
        get(res, "status") === 201 &&
        res?.data != undefined &&
        res?.data?.ok === true
      ) {
        return state;
      }
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async UPLOAD_POINTS(state, data) {
    data.toggleLoading();
    try {
      state.points = {
        data: [],
        point_upload_id: null,
        total: 0,
        error: "",
      };
      const res = await REQUEST_POST(
        "/api/point-transactions/upload",
        jsonToForm(data.form)
      );
      if (res?.data?.ok) {
        state.points.data = res?.data?.result || [];
        state.points.point_upload_id = res?.data?.point_upload_id || null;
        state.points.total = res?.data?.result?.length || 0;
        data.toggleLoading();
        const alert = new AlertMessage(
          GLOBAL_MESSAGE.UPLOAD.SUCCESS,
          "ok",
          "success"
        );
        data.toggleAlert(alert);
      } else {
        console.log(res?.data);
        state.points.data = [];
        state.points.point_upload_id = null;
        state.points.total = 0;
        state.points.error = res?.data?.error;
        data.toggleLoading();

        let alert = new AlertMessage(res.data?.error, "error", "error");
        data.toggleAlert(alert);
      }
      //data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      const alert = new AlertMessage(
          GLOBAL_MESSAGE.UPLOAD.ERROR,
          "error", "error"
        );
        data.toggleAlert(alert);
      return error;
    }
  },
  async DELETE_POINT(state, data) {
    data.toggleLoading();
    try {
      state.points.data
        .filter((x) => x.no === data?.row_number)
        .forEach((item) => (item.isDelete = true));
      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      return error;
    }
  },
  async CLEAR_POINTS(state, data) {
    data.toggleLoading();
    try {
      state.points = {
        ...state.points,
        point_upload_id: null,
        total: 0,
        data: [],
      };
      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      return error;
    }
  },
};

export const actions = {
  async savePoints(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.SAVE_POINTS(state, { toggleLoading, form, toggleAlert });
  },
  async uploadPoints(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
      
    return mutations.UPLOAD_POINTS(state, { toggleLoading, form, toggleAlert });
  },
  async deletePoint(context, row_number) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.DELETE_POINT(state, {
      toggleLoading,
      row_number,
      toggleAlert,
    });
  },
  async clearPoints(context) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.CLEAR_POINTS(state, { toggleLoading, toggleAlert });
  },
};

export const getters = {
  // Add Logic Before Computed
  points(state) {
    // eslint-disable-line
    return state.points;
  },
};

export const points = {
  state,
  mutations,
  actions,
  getters,
};
