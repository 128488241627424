<template>
  <div class="manage-ratio-container">
    <v-breadcrumbs :items="breadcrumbs" class="pa-0">
      <template v-slot:divider>
        <v-icon size="x-large"> mdi-chevron-right</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click="onClickBreadcrumbs(item.id)">
          <span class="breadcrumbs_item"> {{ item.title }} </span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <div v-if="!isDetailRatio">
      <Card>
        <v-form ref="selectForm" @submit.prevent="submitHandler">
          <div class="d-flex flex-column flex-md-row justify-content-between">
            <div class="col">
              <v-select
                label="เลือก SKU"
                :items="materialmasters"
                v-model="selectedSKU"
                outlined
                dense
                background-color="#fff"
                item-text="materialTextName"
                item-value="materialCode"
                return-object
                clear-icon="mdi-close-circle"
                clearable
              ></v-select>
            </div>
            <div class="col">
              <DatePicker
                label="วันที่เริ่มต้น"
                :max="endDate"
                :onSelected="onSelectedStart"
                :required="
                  startDate === '' && endDate === '' ? false : requiredStartDate
                "
                :isClearDate="startDate ? true : false"
                :onClearDate="onClearStartDate"
              />
            </div>
            <div class="col">
              <DatePicker
                label="วันที่สิ้นสุด"
                :min="startDate"
                :onSelected="onSelectedEnd"
                :required="
                  startDate === '' && endDate === '' ? false : requiredEndDate
                "
                :isClearDate="endDate ? true : false"
                :onClearDate="onClearEndDate"
              />
            </div>
            <div class="col-3 d-flex">
              <div class="mr-5">
                <v-btn
                  min-width="40"
                  height="40"
                  color="primary"
                  depressed
                  type="submit"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </div>
              <div>
                <v-btn
                  width="120"
                  height="40"
                  color="primary"
                  depressed
                  @click="goToCreateRatioPage"
                >
                  Create Ratio
                </v-btn>
              </div>
            </div>
          </div>
        </v-form>
        <div v-if="dataList !== undefined && dataList.length > 0">
          <v-data-table
            class="mx-0 mx-md-4"
            :loading="isLoading"
            :headers="headers"
            :page.sync="page"
            :items-per-page="perPage"
            :items="dataList"
            hide-default-footer
            :page-count="pageCount"
            no-data-text="ไม่พบข้อมูล"
            no-results-text="ไม่พบข้อมูล"
          >
            <template v-slot:item.no="{ item }">
              <div>{{ item.no }}</div>
            </template>
            <template v-slot:item.sku="{ item }">
              <div>{{ item.sku }}</div>
            </template>
            <template v-slot:item.startDate="{ item }">
              <div>{{ item.startDate }}</div>
            </template>
            <template v-slot:item.endDate="{ item }">
              <div>{{ item.endDate }}</div>
            </template>
            <template v-slot:item.operationValue="{ item }">
              <div>{{ item.operationValue }}</div>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn v-if="item.isEdit" icon @click="updateItem(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <Paginations
            :page="page"
            :pageCount="pageCount"
            :total="dataTotal"
            :itemsPerPage="perPage"
            :itemsPerPageOptions="itemsPerPageOptions"
            :onChangePage="onChangePage"
          />
        </div>
        <div class="d-flex justify-center mt-3" v-else>
          <h3>ไม่พบข้อมูล</h3>
        </div>
      </Card>
    </div>
    <div v-else>
      <CreateRatio
        :skuList="materialmasters"
        :onSaveComplete="onSaveComplete"
        :errorMessage="errorMessage"
        :isEdit="isEdit"
        :dateUpdate="dataUpdate"
      />
    </div>
    <ConfirmModal
      v-model="updateConf.status"
      :data="updateConf"
      @confirm="updateData"
    />
  </div>
</template>
<script>
import CreateRatio from "@/components/CreateRatio";
import DatePicker from "@/components/DatePicker";
import Card from "@/components/Card";
import { reduce } from "lodash";
import Utils from "@/mixin";
import Paginations from "@/components/Paginations";
import { itemsPerPageOptions } from "@/plugins/common";
import ConfirmModal from "@/components/ConfirmModal";
export default {
  mixins: [Utils],
  name: "ManageRatio",
  data: () => ({
    page: 1,
    itemsPerPageOptions: [],
    selectedSKU: {},
    breadcrumbs: [{ id: "manage-ratio", title: "Manage Ratio" }],
    isDetailRatio: false,
    startDate: "",
    endDate: "",
    errorMessage: "",
    requiredStartDate: false,
    requiredEndDate: false,
    headers: [
      { text: "No", value: "no", sortable: false, dataType: "number" },
      { text: "SKU", value: "sku", sortable: false },
      { text: "วันที่เริ่มต้น", value: "startDate", sortable: false },
      { text: "วันที่สิ้นสุด", value: "endDate", sortable: false },
      {
        text: "Operation Value",
        value: "point",
        sortable: false,
        dataType: "number",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        align: "center",
      },
    ],
    isEdit: false,
    updateConf: {
      status: false,
      title: "ยืนยันการแก้ไขข้อมูล",
    },
    dataUpdate: {},
  }),
  components: {
    CreateRatio,
    Card,
    DatePicker,
    Paginations,
    ConfirmModal,
  },
  computed: {
    materialmasters() {
      const { materialMastersList } = this;

      let result = reduce(
        materialMastersList,
        (a, b) => {
          let name = b.materialShortName + " " + b.materialGroup;
          a.push({
            ...b,
            materialTextName: name,
          });
          return a;
        },
        []
      );
      return result;
    },
    dataList() {
      const { materialRatiosList } = this;
      const result = reduce(
        materialRatiosList.data,
        (a, b) => {
          a.push({
            ...b,
          });
          return a;
        },
        []
      );
      return result;
    },
    dataTotal() {
      const { materialRatiosList } = this;
      return materialRatiosList.total;
    },
    perPage() {
      const { materialRatiosList } = this;
      return materialRatiosList.perPage;
    },
    pageCount() {
      const { materialRatiosList } = this;
      return materialRatiosList.pageCount;
    },
  },
  methods: {
    async getInitialMasterData() {
      const fetchMaterialmasters = () => this.getMaterialMastersList();
      await fetchMaterialmasters();
    },
    async getInitialData() {
      if (!this.isDetailRatio) {
        let condition = {};

        if (this.selectedSKU) {
          if (this.selectedSKU.materialShortName !== undefined) {
            condition.query = this.selectedSKU.materialShortName;
          }
        }

        condition.startDate = this.startDate;
        condition.endDate = this.endDate;

        condition.limit = this.perPage ? this.perPage : 50;
        condition.page = this.page ? this.page : 1;

        const fetchRatiosData = () => this.getMaterialRatiosList(condition);
        await fetchRatiosData();
      }
    },
    onClickBreadcrumbs(id) {
      this.errorMessage = "";
      if (id === "create-ratio") {
        this.isDetailRatio = true;
        this.isEdit = false;
        this.dataUpdate = {};
      } else {
        this.isDetailRatio = false;

        if (this.breadcrumbs.length > 1) this.breadcrumbs.pop();
      }
    },
    goToCreateRatioPage() {
      this.isDetailRatio = true;
      this.isEdit = false;
      this.dataUpdate = {};
      this.breadcrumbs.push({ id: "create-ratio", title: "Create Ratio" });
    },
    onSelectedStart(value) {
      this.startDate = value;
    },
    onSelectedEnd(value) {
      this.endDate = value;
    },

    onChangePage(value) {
      this.page = value;
      this.getInitialData();
    },
    initialData() {
      this.errorMessage = "";
      this.page = 1;
    },
    submitHandler() {
      let self = this;
      setTimeout(function () {
        if (self.$refs.selectForm.validate()) {
          self.onSearch();
        }
      });
    },
    onSearch() {
      this.initialData();
      this.getInitialData();
    },
    onSaveComplete() {
      const { manageRatio } = this;
      if (!manageRatio.error) {
        this.isDetailRatio = false;
        this.errorMessage = "";
        this.initialData();
        this.getInitialData();
        if (this.breadcrumbs.length > 1) this.breadcrumbs.pop();
      } else {
        this.errorMessage = manageRatio.errorMessage;
      }
    },
    clearSelectedSKU() {
      this.selectedSKU = {};
      this.initialData();
      this.getInitialData();
    },
    onClearStartDate() {
      this.startDate = "";
      if (this.endDate !== "") {
        this.requiredStartDate = true;
      } else {
        this.initialData();
        this.getInitialData();
      }
    },
    onClearEndDate() {
      this.endDate = "";
      if (this.startDate !== "") {
        this.requiredStartDate = true;
      } else {
        this.initialData();
        this.getInitialData();
      }
    },
    updateItem(data) {
      this.updateConf = {
        ...this.updateConf,
        status: true,
        data,
      };
    },
    async updateData(value = false) {
      const { updateConf } = this;
      this.isEdit = value;

      if (value) {
        this.isDetailRatio = true;
        this.dataUpdate = updateConf.data;
        this.breadcrumbs.push({ id: "update-ratio", title: "Update Ratio" });
      }
      this.updateConf = {
        ...updateConf,
        status: false,
        data: undefined,
      };
    },
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0 });
    this.getInitialMasterData();
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  watch: {
    startDate: {
      handler() {
        if (this.startDate === "") {
          if (this.endDate !== "") {
            this.requiredStartDate = true;
          } else {
            this.requiredStartDate = false;
          }
        } else {
          if (this.endDate === "") {
            this.requiredEndDate = true;
          } else {
            this.requiredEndDate = false;
          }
        }
      },
      deep: false,
    },
    endDate: {
      handler() {
        if (this.endDate === "") {
          if (this.startDate !== "") {
            this.requiredEndDate = true;
          } else {
            this.requiredStartDate = false;
          }
        } else {
          if (this.startDate === "") {
            this.requiredStartDate = true;
          } else {
            this.requiredStartDate = false;
          }
        }
      },
      deep: false,
    },
  },
};
</script>
<style lang="scss">
.manage-ratio-container {
  padding: 40px 25px;
  .secondary-btn {
    color: #000 !important;
  }
  .v-pagination {
    @media screen and (max-width: 600px) {
      justify-content: center;
    }
    justify-content: flex-start;
  }
}

.breadcrumbs_item {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  cursor: pointer;
}
</style>
