<template>
  <div class="summary-review-container">
    <v-breadcrumbs :items="breadcrumbs" class="pa-0">
      <template v-slot:divider>
        <v-icon size="x-large"> mdi-chevron-right</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click="onClickBreadcrumbs(item.id)">
          <span class="breadcrumbs_item"> {{ item.title }} </span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>

    <div v-if="isSummaryReview">
      <SummaryReview
        :page="page"
        :dateSentPoint="dateSentPoint"
        :dataTotal="dataTotal"
        :pageCount="pageCount"
        :perPage="perPage"
        :headers="headers"
        :dataList="dataList"
        :onReviewItem="reviewItem"
        :perPageOptions="itemsPerPageOptions"
        :onChangePage="onChangePage"
        :onSearch="onSearch"
      />
    </div>
    <div v-if="isDetailReview">
      <div v-if="dataDetailList !== undefined && dataDetailList.length > 0">
        <Card>
          <v-data-table
            class="mx-0 mx-md-4"
            :loading="isLoading"
            :headers="headersDetail"
            :page.sync="page"
            :items-per-page="perPage"
            :items="dataDetailList"
            hide-default-footer
            :page-count="pageCount"
            no-data-text="ไม่พบข้อมูล"
            no-results-text="ไม่พบข้อมูล"
          >
            <template v-slot:item.qty="{ item }">
              <div>{{ $numberFormat(item.qty) }}</div>
            </template>
          </v-data-table>
          <Paginations
            :page="page"
            :pageCount="pageCount"
            :total="dataTotal"
            :itemsPerPage="perPage"
            :itemsPerPageOptions="itemsPerPageOptions"
            :onChangePage="onChangePage"
          />
          <v-col class="text-end">
            <v-btn height="40" color="primary" depressed @click="onReviewPoint">
              Review Summary
            </v-btn>
          </v-col>
        </Card>
      </div>
      <div class="d-flex justify-center mt-3" v-else>
        <h3>ไม่พบข้อมูล</h3>
      </div>
    </div>
    <div v-if="isDetailReviewPoint">
      <div class="ml-5 mt-3">
        <div v-for="(itemData, index) in currentData" :key="index">
          <PointSummaryItems
            :shopName="itemData.shopName"
            :shopId="itemData.shopId ? itemData.shopId.toString() : ``"
            :headers="headersReviewPoint"
            :dataRow="itemData.items"
          />
        </div>
        <div v-if="dataDetailPoint && dataDetailPoint.length > 0">
          <div class="mt-5 mb-5">
            {{ currentStore }} จาก {{ dataDetailPoint.length }} ร้าน
          </div>
          <v-pagination
            v-model="currentStore"
            :length="dataDetailPoint.length"
            color="primary"
          />
        </div>
        <div v-if="!isLoading && summaryPointSendMode === 'Manual'">
          <v-col class="text-right" cols="12" md="auto">
            <v-btn
              class="secondary-btn mr-5"
              width="120"
              height="40"
              color="secondary"
              depressed
              @click="cancelItem"
            >
              Cancel
            </v-btn>
            <v-btn
              width="120"
              height="40"
              color="primary"
              depressed
              @click="onSaveDataConfirm"
            >
              Submit
            </v-btn>
          </v-col>
        </div>
      </div>
    </div>
    <ConfirmModal
      v-model="cancelConf.status"
      :data="cancelConf"
      @confirm="clearData"
    />
    <ConfirmModal
      v-model="saveConfirm.status"
      :data="saveConfirm"
      @confirm="onSaveSaveData"
    />
  </div>
</template>
<script>
import Card from "@/components/Card";
import { reduce } from "lodash";
import Paginations from "@/components/Paginations";
import SummaryReview from "@/components/SummaryReview";
import PointSummaryItems from "@/components/PointSummaryItems";
import { itemsPerPageOptions } from "@/plugins/common";
import ConfirmModal from "@/components/ConfirmModal";
import Utils from "@/mixin";
export default {
  name: "Summary-Review",
  mixins: [Utils],
  components: {
    Card,
    SummaryReview,
    PointSummaryItems,
    ConfirmModal,
    Paginations,
  },
  data: () => ({
    currentStore: 1,
    dateSentPoint: null,
    summaryPointSendMode: "Manual",
    page: 1,
    keywordSearch: "",
    errorMessage: "",
    itemsPerPageOptions: [],
    pointUploadId: null,
    isSummaryReview: true,
    isDetailReview: false,
    isDetailReviewPoint: false,
    breadcrumbs: [{ id: "summary-review", title: "Review Summary" }],
    headers: [
      { text: "ลำดับ", value: "no", sortable: false },
      { text: "ชื่อไฟล์", value: "fileName" },
      { text: "Date Create", value: "createdAt" },
      { text: "Action", value: "action", sortable: false, align: "center" },
    ],
    headersDetail: [
      { text: "ลำดับ", value: "no", sortable: false },
      { text: "เลขที่เอกสาร", value: "documentNo" },
      { text: "รหัสร้านค้า", value: "shopId" },
      { text: "ชื่อร้านค้า", value: "shopName" },
      { text: "เบอร์โทรศัพท์", value: "phone" },
      { text: "SKU", value: "sku" },
      { text: "จำนวน SKU", value: "qty", sortable: false },
      { text: "Date Create", value: "createdAt" },
    ],
    headersReviewPoint: [
      { text: "ลำดับ", value: "no", sortable: false, class: "col-1" },
      { text: "เบอร์โทรศัพท์", value: "phone", class: "col-2 text-center" },
      { text: "SKU", value: "sku", class: "col-2 text-center" },
      {
        text: "จำนวน SKU",
        value: "qty",
        sortable: false,
        class: "col-2 text-right",
        dataType: "number",
      },
      {
        text: "คะแนนที่ได้",
        value: "point",
        sortable: false,
        class: "col-2 text-right",
        dataType: "number",
      },
      { text: "Date Create", value: "createdAt", class: "col-3 text-center" },
    ],
    cancelConf: {
      title: "ยืนยันการยกเลิกข้อมูล",
      status: false,
    },
    saveConfirm: {
      title: "ยืนยันการบันทึกข้อมูล",
      status: false,
    },
  }),
  computed: {
    dataList() {
      const { summaryReview } = this;
      const result = reduce(
        summaryReview.data,
        (a, b) => {
          a.push({
            ...b,
          });
          return a;
        },
        []
      );
      return result;
    },
    dataDetailList() {
      const { summaryReviewDetail } = this;
      const result = reduce(
        summaryReviewDetail.data,
        (a, b) => {
          a.push({
            ...b,
          });
          return a;
        },
        []
      );
      return result;
    },
    dataDetailPoint() {
      const { summaryReviewPointDetail } = this;
      const result = reduce(
        summaryReviewPointDetail.data,
        (a, b) => {
          b.items.map((a, index) => {
            a.no = index + 1;
          });

          a.push({
            ...b,
          });
          return a;
        },
        []
      );

      return result;
    },
    currentData() {
      let result = [];
      let dataDetailList = this.dataDetailPoint;

      if (dataDetailList.length > 0) {
        result.push(dataDetailList[this.currentStore - 1]);
      }
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return result;
    },
    dataTotal() {
      let total = 0;
      if (this.isSummaryReview) {
        const { summaryReview } = this;
        total = summaryReview.total;
      }
      if (this.isDetailReview) {
        const { summaryReviewDetail } = this;
        total = summaryReviewDetail.total;
      }
      if (this.isDetailReviewPoint) {
        total = 70;
      }
      return total;
    },
    pageCount() {
      let pageCount = 0;
      if (this.isSummaryReview) {
        const { summaryReview } = this;
        pageCount = summaryReview.pageCount;
      }
      if (this.isDetailReview) {
        const { summaryReviewDetail } = this;
        pageCount = summaryReviewDetail.pageCount;
      }
      if (this.isDetailReviewPoint) {
        pageCount = 2;
      }
      return pageCount;
    },
    perPage() {
      let perPage = 0;
      if (this.isSummaryReview) {
        const { summaryReview } = this;
        perPage = summaryReview.perPage;
      }
      if (this.isDetailReview) {
        const { summaryReviewDetail } = this;
        perPage = summaryReviewDetail.perPage;
      }
      if (this.isDetailReviewPoint) {
        perPage = 50;
      }
      return perPage;
    },
  },

  methods: {
    async getInitialConfigDataData() {
      this.errorMessage = "";
      const fetchSummaryPointsConfigData = () => this.getSummaryPointsConfig();
      await fetchSummaryPointsConfigData();
      const { submittingScores } = this;
      if (submittingScores) {
        this.summaryPointSendMode = submittingScores.summaryPointSendMode;
        if (submittingScores.summaryPointSendMode === "Auto") {
          this.dateSentPoint = submittingScores.summaryPointSendDay;
        } else {
          this.dateSentPoint = null;
        }
      }
    },
    async getInitialData() {
      if (this.isSummaryReview) {
        let condition = {};

        condition.query = this.keywordSearch ? this.keywordSearch : "";
        condition.limit = this.perPage ? this.perPage : 50;
        condition.page = this.page ? this.page : 1;
        const fetchSummaryReviewData = () =>
          this.getSummaryReviewList(condition);
        await fetchSummaryReviewData();
      }
    },
    async getInitialSummaryDetail() {
      let condition = {};
      condition.limit = this.perPage ? this.perPage : 50;
      condition.page = this.page ? this.page : 1;
      condition.pointUploadId = this.pointUploadId ?? null;

      const fetchSummaryReviewDetail = () =>
        this.getSummaryReviewDetailList(condition);
      await fetchSummaryReviewDetail();
    },

    onSearch(textSearch) {
      this.keywordSearch = textSearch;
      this.page = 1;
      this.getInitialData();
    },
    onChangePage(value) {
      this.page = value;
      if (this.isSummaryReview) {
        this.getInitialData();
      }
      if (this.isDetailReview) {
        this.getInitialSummaryDetail();
      }
      if (this.isDetailReviewPoint) {
        console.log("onChangePage", this.page);
      }
    },
    onClickBreadcrumbs(id) {
      this.keywordSearch = "";
      this.page = 1;
      this.currentStore = 1;

      if (id === "summary-detail") {
        if (this.breadcrumbs.length === 3) {
          this.isSummaryReview = false;
          this.isDetailReview = true;
          this.isDetailReviewPoint = false;
          this.getInitialSummaryDetail();
          if (this.breadcrumbs.length > 1) this.breadcrumbs.pop();
        }
      } else if (id === "summary-review") {
        this.isSummaryReview = true;
        this.isDetailReview = false;
        this.isDetailReviewPoint = false;
        this.pointUploadId = null;
        this.getInitialData();
        this.breadcrumbs = [{ id: "summary-review", title: "Review Summary" }];
      }
    },
    async reviewItem(data) {
      this.keywordSearch = "";
      this.page = 1;
      this.isSummaryReview = false;
      this.isDetailReview = true;
      this.isDetailReviewPoint = false;
      this.pointUploadId = data.pointUploadId;
      this.getInitialSummaryDetail();
      this.breadcrumbs.push({ id: "summary-detail", title: data?.fileName });
    },
    async onReviewPoint() {
      let condition = {};
      condition.pointUploadId = this.pointUploadId ?? null;

      const fetchSummaryReviewPointDetail = () =>
        this.getSummaryReviewDetailPointList(condition);
      await fetchSummaryReviewPointDetail();

      const { summaryReviewPointDetail } = this;

      if (summaryReviewPointDetail.error === "") {
        this.isSummaryReview = false;
        this.isDetailReview = false;
        this.isDetailReviewPoint = true;
        this.breadcrumbs.push({ id: "summary-point-detail", title: "Review" });
      } else {
        this.isSummaryReview = false;
        this.isDetailReview = true;
        this.isDetailReviewPoint = false;
      }
    },
    cancelItem() {
      this.cancelConf = {
        ...this.cancelConf,
        status: true,
      };
    },
    async clearData(value = false) {
      if (value) {
        await this.clearSummary();
        this.isSummaryReview = true;
        this.isDetailReview = false;
        this.isDetailReviewPoint = false;
        this.pointUploadId = null;
        this.breadcrumbs = [{ id: "summary-review", title: "Review Summary" }];
      }

      this.cancelConf = {
        ...this.cancelConf,
        status: false,
        data: undefined,
      };
    },
    onSaveDataConfirm() {
      this.saveConfirm = {
        ...this.saveConfirm,
        status: true,
      };
    },
    async onSaveSaveData(value = false) {
      if (value) {
        await this.saveSummary({
          pointUploadId: this.pointUploadId,
        });
      }
      this.saveConfirm = {
        ...this.saveConfirm,
        status: false,
      };
      const { summaryReview } = this;
      if (summaryReview.error) {
        this.errorMessage = summaryReview.errorMessage;
      } else {
        this.isSummaryReview = true;
        this.isDetailReview = false;
        this.isDetailReviewPoint = false;
        this.pointUploadId = null;
        this.breadcrumbs = [{ id: "summary-review", title: "Review Summary" }];
        this.getInitialData();
      }
    },
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0 });
    this.getInitialConfigDataData();
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
};
</script>

<style lang="scss">
.summary-review-container {
  padding: 40px 25px;
  .secondary-btn {
    color: #000 !important;
  }
  .v-pagination {
    @media screen and (max-width: 600px) {
      justify-content: center;
    }
    justify-content: flex-start;
  }
}
</style>
