import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/Login.vue'

import Home from '@/views/Home.vue'

import ApprovedTransaction from '@/views/Auth/ApprovedTransaction.vue'
import Point from '@/views/Auth/Point.vue'
import ManageTarget from '@/views/Target/ManageTarget.vue'
import ManageRatio from '@/views/Ratio/ManageRatio.vue'
import RejectedTransaction from '@/views/Auth/RejectedTransaction.vue'
import WaitingApproval from '@/views/Auth/WaitingApproval.vue'
import SummaryReview from '@/views/Summary/SummaryReview.vue'
import SubmittingScores from '@/views/Setting/ SubmittingScores.vue'

import NotFound from '@/views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'homeLayout',
    },
    component: Login,
  },
  {
    path: '/',
    name: 'Home',
    meta: {
      layout: 'authLayout',
    },
    component: Home,
  },
  {
    path: '/upload-point',
    name: 'Point',
    meta: {
      layout: 'authLayout',
    },
    component: Point,
  },
  {
    path: '/waiting-approval',
    name: 'Waiting-Approval',
    meta: {
      layout: 'authLayout',
    },
    component: WaitingApproval,
  },
  {
    path: '/rejected-transaction',
    name: 'Rejected-Transaction',
    meta: {
      layout: 'authLayout',
    },
    component: RejectedTransaction,
  },
  {
    path: '/approved-transaction',
    name: 'Approved-Transaction',
    meta: {
      layout: 'authLayout',
    },
    component: ApprovedTransaction,
  },
  // {
  //   path: '/sku-settings',
  //   name: 'SKU Settings',
  //   meta: {
  //     layout: 'authLayout',
  //   },
  //   component: Home,
  // },
  {
    path: '/manage-target',
    name: 'Manage-Target',
    meta: {
      layout: 'authLayout',
    },
    component: ManageTarget,
  },
  {
    path: '/manage-ratio',
    name: 'Manage-Ratio',
    meta: {
      layout: 'authLayout',
    },
    component: ManageRatio,
  },
   {
    path: '/summary-review',
    name: 'Summary-Review',
    meta: {
      layout: 'authLayout',
    },
    component: SummaryReview,
  },
   {
    path: '/submitting-scores',
    name: 'Submitting-Scores',
    meta: {
      layout: 'authLayout',
    },
    component: SubmittingScores,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
