import { REQUEST_GET, REQUEST_PUT } from "./API_Request";
import { AlertMessage, errorMessage } from "@/plugins/common";
import { GLOBAL_MESSAGE } from "@/plugins/message";

export const state = {
  approvedTransactions: {
    data: [],
    total: 0,
    perPage: 50,
    pageCount: 1,
  },
  rejectedTransactions: {
    data: [],
    total: 0,
    fileName: "",
    remark: "",
    dateReject: "",
    status: "",
    perPage: 50,
    pageCount: 1,
  },
  waitingTransactions: {
    data: [],
    total: 0,
    perPage: 50,
    pageCount: 1,
    pointUploadId: null,
  },
};

export const mutations = {
  async GET_TBR_ADMIN_TRANSACTIONS(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET(
        "/api/point-transactions/waiting",
        data.form
      );
      if (res?.data?.data !== undefined && res?.data?.data.length > 0) {
        state.waitingTransactions.data = res?.data?.data || [];
        state.waitingTransactions.total = res?.data?.total || 0;
        state.waitingTransactions.pointUploadId = res?.data?.data[0]?.fileId;
        state.waitingTransactions.perPage = res?.data?.perPage || 50;
        state.waitingTransactions.pageCount = res?.data?.lastPage || 1;
      } else {
        state.waitingTransactions = {
          ...state.waitingTransactions,
          data: [],
          total: 0,
          perPage: 50,
          pageCount: 1,
          pointUploadId: null,
        };
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_TBR_TRANSACTIONS(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET(
        "/api/point-transactions/tbr/waiting",
        data.form
      );
      if (res?.data?.data !== undefined && res?.data?.data.length > 0) {
        state.waitingTransactions.data = res?.data?.data || [];
        state.waitingTransactions.total = res?.data?.total || 0;
        state.waitingTransactions.pointUploadId = res?.data?.data[0]?.fileId;
        state.waitingTransactions.perPage = res?.data?.perPage || 50;
        state.waitingTransactions.pageCount = res?.data?.lastPage || 1;
      } else {
        state.waitingTransactions = {
          ...state.waitingTransactions,
          data: [],
          total: 0,
          perPage: 50,
          pageCount: 1,
          pointUploadId: null,
        };
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_MM_TRANSACTIONS(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET(
        "/api/point-transactions/mm/waiting",
        data.form
      );
      if (res?.data?.data !== undefined && res?.data?.data.length > 0) {
        state.waitingTransactions.data = res?.data?.data || [];
        state.waitingTransactions.total = res?.data?.total || 0;
        state.waitingTransactions.pointUploadId = res?.data?.data[0]?.fileId;
        state.waitingTransactions.perPage = res?.data?.perPage || 50;
        state.waitingTransactions.pageCount = res?.data?.lastPage || 1;
      } else {
        state.waitingTransactions = {
          ...state.waitingTransactions,
          data: [],
          total: 0,
          perPage: 50,
          pageCount: 1,
          pointUploadId: null,
        };
      }
      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_MM_TRANSACTIONS_DONE(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET(
        "/api/point-transactions/approved",
        data.form
      );
      if (res?.data?.data !== undefined && res?.data?.data.length > 0) {
        state.waitingTransactions.data = res?.data?.data || [];
        state.waitingTransactions.total = res?.data?.total || 0;
        state.waitingTransactions.pointUploadId = res?.data?.data[0]?.fileId;
        state.waitingTransactions.perPage = res?.data?.perPage || 50;
        state.waitingTransactions.pageCount = res?.data?.lastPage || 1;
      } else {
        state.waitingTransactions = {
          ...state.waitingTransactions,
          data: [],
          total: 0,
          perPage: 50,
          pageCount: 1,
          pointUploadId: null,
        };
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_REJECTED_TRANSACTIONS_LIST(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET(
        "/api/point-transactions/reject-history",
        data.form
      );

      if (res?.data?.data != undefined && res?.data?.data.length > 0) {
        state.rejectedTransactions.data = res?.data?.data || [];
        state.rejectedTransactions.total = res?.data?.total || 0;
        state.rejectedTransactions.perPage = res?.data?.perPage || 50;
        state.rejectedTransactions.pageCount = res?.data?.lastPage || 1;
      } else {
        state.rejectedTransactions = {
          ...state.rejectedTransactions,
          data: [],
          total: 0,
          fileName: "",
          remark: "",
          dateReject: "",
          status: "",
          perPage: 50,
          pageCount: 1,
        };
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_REJECTED_TRANSACTIONS(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET(
        "/api/point-transactions/rejected",
        data.form
      );
      if (res?.data?.data != undefined && res?.data?.data.length > 0) {
        state.rejectedTransactions.data = res?.data?.data || [];
        state.rejectedTransactions.total = res?.data?.total || 0;
        state.rejectedTransactions.fileName =
          res?.data?.data[0]?.fileName || "";
        state.rejectedTransactions.remark = res?.data?.data[0]?.remark || "";
        state.rejectedTransactions.dateReject =
          res?.data?.data[0]?.dateReject || "";
        state.rejectedTransactions.status = res?.data?.data[0]?.status || "";
        state.rejectedTransactions.perPage = res?.data?.perPage || 50;
        state.rejectedTransactions.pageCount = res?.data?.lastPage || 1;
      } else {
        state.rejectedTransactions = {
          ...state.rejectedTransactions,
          data: [],
          total: 0,
          fileName: "",
          remark: "",
          dateReject: "",
          status: "",
          perPage: 50,
          pageCount: 1,
        };
      }
      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_APPROVED_TRANSACTIONS(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET(
        "/api/point-transactions/approved",
        data.form
      );
      if (res?.data?.data && res?.data?.data.length > 0) {
        state.approvedTransactions.data = res?.data?.data || [];
        state.approvedTransactions.total = res?.data?.total || 0;
        state.approvedTransactions.perPage = res?.data?.perPage || 50;
        state.approvedTransactions.pageCount = res?.data?.lastPage || 1;
      } else {
        state.approvedTransactions = {
          ...state.approvedTransactions,
          data: [],
          total: 0,
          perPage: 50,
          pageCount: 1,
        };
      }
      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async APPROVE_TBR_TRANSACTION(state, data) {
    data.toggleLoading();
    try {
      let body = {
        pointUploadId: state.waitingTransactions?.pointUploadId,
      };
      await REQUEST_PUT(`/api/point-transactions/tbr/approve`, body);
      data.toggleLoading();

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.APPROVE.SUCCESS,
        "ok",
        "success"
      );
      data.toggleAlert(alert);
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.APPROVE.ERROR,
        "error",
        "error"
      );
      data.toggleAlert(alert);
      return error;
    }
  },
  async REJECT_TBR_TRANSACTION(state, data) {
    data.toggleLoading();
    try {
      let body = {
        pointUploadId: state.waitingTransactions?.pointUploadId,
        remark: data?.form?.remark,
      };
      await REQUEST_PUT(`/api/point-transactions/tbr/reject`, body);
      data.toggleLoading();

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.REJECT.SUCCESS,
        "ok",
        "success"
      );
      data.toggleAlert(alert);
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.REJECT.ERROR,
        "error",
        "error"
      );
      data.toggleAlert(alert);
      return error;
    }
  },
  async APPROVE_MM_TRANSACTION(state, data) {
    data.toggleLoading();
    try {
      let body = {
        pointUploadId: state.waitingTransactions?.pointUploadId,
      };
      await REQUEST_PUT(`/api/point-transactions/mm/approve`, body);
      data.toggleLoading();

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.APPROVE.SUCCESS,
        "ok",
        "success"
      );
      data.toggleAlert(alert);
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.APPROVE.ERROR,
        "error",
        "error"
      );
      data.toggleAlert(alert);
      return error;
    }
  },
  async REJECT_MM_TRANSACTION(state, data) {
    data.toggleLoading();
    try {
      let body = {
        pointUploadId: state.waitingTransactions?.pointUploadId,
        remark: data?.form?.remark,
      };
      await REQUEST_PUT(`/api/point-transactions/mm/reject`, body);
      data.toggleLoading();

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.REJECT.SUCCESS,
        "ok",
        "success"
      );
      data.toggleAlert(alert);
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.REJECT.ERROR,
        "error",
        "error"
      );
      data.toggleAlert(alert);
      return error;
    }
  },
};

export const actions = {
  async getTbrAdminTransactions(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_TBR_ADMIN_TRANSACTIONS(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getTbrTransactions(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_TBR_TRANSACTIONS(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getMMTransactions(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_MM_TRANSACTIONS(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getMMTransactionsDone(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_MM_TRANSACTIONS_DONE(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getRejectedTransactions(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_REJECTED_TRANSACTIONS(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getRejectedTransactionsList(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_REJECTED_TRANSACTIONS_LIST(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getApprovedTransactions(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_APPROVED_TRANSACTIONS(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async approveTbrTransaction(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.APPROVE_TBR_TRANSACTION(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async rejectTbrTransaction(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.REJECT_TBR_TRANSACTION(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async approveMmTransaction(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.APPROVE_MM_TRANSACTION(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async rejectMmTransaction(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.REJECT_MM_TRANSACTION(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
};

export const getters = {
  // Add Logic Before Computed
  approvedTransactions(state) {
    // eslint-disable-line
    return state.approvedTransactions;
  },
  rejectedTransactions(state) {
    // eslint-disable-line
    return state.rejectedTransactions;
  },
  waitingTransactions(state) {
    // eslint-disable-line
    return state.waitingTransactions;
  },
};

export const transactions = {
  state,
  mutations,
  actions,
  getters,
};
