<template>
  <div>
    <Card>
      <v-form ref="entryForm" @submit.prevent="submitHandler">
        <div v-if="errorMessage" class="d-flex flex-column col">
          <h4 class="red--text">*** {{ errorMessage }}</h4>
        </div>

        <div class="d-flex flex-column col-3">
          <div class="my-3">
            <div>
              <h4>SKU</h4>
            </div>
            <div>
              <v-select
                :items="skuList"
                v-model="selectedSKU"
                outlined
                dense
                :disabled="isEdit"
                background-color="#fff"
                item-text="materialTextName"
                item-value="materialCode"
                :rules="[(v) => !!v || 'กรุณาเลือก SKU']"
                required
              ></v-select>
            </div>
          </div>

          <div class="my-3">
            <div>
              <h4>ระบุ เดือน/ปี Ratio พิเศษ</h4>
            </div>
            <div>
              <v-menu
                v-model="menuSpecial"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                location="bottom"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedSpecialDate"
                    outlined
                    background-color="#fff"
                    dense
                    v-bind="attrs"
                    readonly
                    v-on="on"
                    required
                    :rules="rules"
                    prepend-inner-icon="mdi-calendar-blank-outline"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="dateSpecial"
                  no-title
                  type="month"
                  color="primary"
                  :min="minSpecialDate"
                  @input="menuSpecial = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>
          <div class="my-3">
            <div>
              <h4>Operation Value</h4>
            </div>

            <div>
              <v-text-field
                v-model.number="operationValue"
                dense
                background-color="#fff"
                outlined
                required
                :rules="numberRules"
                type="number"
              />
            </div>
          </div>
        </div>
        <div>
          <v-col class="mr-0 mr-md-4 d-md-block d-none" cols="12" md="auto">
            <v-btn
              class="secondary-btn mr-5"
              width="120"
              height="40"
              color="secondary"
              depressed
              @click="cancelItem"
            >
              Cancel
            </v-btn>
            <v-btn
              width="120"
              height="40"
              color="primary"
              depressed
              type="submit"
            >
              {{ isEdit ? "Update" : "Save" }}
            </v-btn>
          </v-col>
        </div>
      </v-form>
    </Card>

    <ConfirmModal
      v-model="cancelConf.status"
      :data="cancelConf"
      @confirm="clearData"
    />
    <ConfirmModal
      v-model="saveConfirm.status"
      :data="saveConfirm"
      @confirm="onSaveSaveData"
    />
  </div>
</template>
<script>
import moment from "moment";
import Utils from "@/mixin";
import Card from "@/components/Card";
import ConfirmModal from "@/components/ConfirmModal";
export default {
  mixins: [Utils],
  components: {
    Card,
    ConfirmModal,
  },
  props: {
    skuList: { type: Array, default: () => [{ data: [] }] },
    onSaveComplete: {
      type: Function,
      default: () => {},
    },
    errorMessage: { type: String, default: "" },
    isEdit: { type: Boolean, default: false },
    dateUpdate: { type: Object },
  },

  data: () => ({
    selectedSKU: "null",
    dateSpecial: "",
    minSpecialDate: "",
    menuSpecial: false,
    selectedSpecialDate: "",
    operationValue: null,
    numberRules: [
      (v) => !!v || "กรุณาระบุจำนวน Operation Value",
      (v) => {
        if (v < 2) return "กรุณาระบุจำนวน Operation Value มากกว่า 1";
      },
      (v) => /^\d+$/.test(v) || "กรุณาระบุเป็นตัวเลขเท่านั้น",
    ],
    cancelConf: {
      title: "ยืนยันการเคลียร์ข้อมูล",
      status: false,
    },
    saveConfirm: {
      title: "ยืนยันการบันทึกข้อมูล",
      status: false,
    },
    rules: [
      (v) => !!v || "กรุณาระบุข้อมูล",
      (v) => /^(0[1-9]|1[0-2])\/\d{4}$/.test(v) || "กรุณาวันที่ รูปแบบ ดด/ปปปป",
    ],
  }),

  mounted() {
    if (this.isEdit) {
      let endDate = this.dateUpdate.endDate.split("/");

      this.dateSpecial = endDate[2] + "-" + endDate[1];
    }
    this.minSpecialDate = moment().add(1, "month").format("YYYY-MM").toString();
  },

  watch: {
    dateSpecial() {
      this.selectedSpecialDate = this.formatSpecialDate(this.dateSpecial);
    },

    dateUpdate: {
      handler(newValue) {
        if (newValue && newValue !== undefined) {
          let convertSpecialDate = "";

          this.selectedSKU = newValue.materialCode;
          this.operationValue = newValue.point;

          if (newValue.startDate !== "" && newValue.startDate !== undefined) {
            let startDate = newValue.startDate.split("/");
            convertSpecialDate = startDate[2] + "-" + startDate[1];
          }

          this.selectedSpecialDate = convertSpecialDate;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    formatSpecialDate(date) {
      if (!date) return null;

      const [year, month] = date.split("-");
      let pickerDate = `${month}/${year}`;

      return pickerDate;
    },

    cancelItem() {
      this.cancelConf = {
        ...this.cancelConf,
        status: true,
      };
    },
    async clearData(value = false) {
      if (value) {
        this.$refs.form && this.$refs.form.reset();
        await this.clearRatio();
        this.onSaveComplete();
      }

      this.cancelConf = {
        ...this.cancelConf,
        status: false,
        data: undefined,
      };
    },
    async onSaveSaveData(value = false) {
      if (value) {
        this.$refs.form && this.$refs.form.reset();
        const startDate = moment(this.dateSpecial, "YYYY-MM")
          .startOf("month")
          .format("YYYY-MM-DD");
        const endDate = moment(this.dateSpecial, "YYYY-MM")
          .endOf("month")
          .format("YYYY-MM-DD");
        console.log(startDate, endDate);
        if (this.isEdit) {
          if (this.dateUpdate?.id)
            await this.updateRatio({
              Id: this.dateUpdate?.id,
              materialCode: this.selectedSKU,
              operationValue: this.operationValue,
              startDate: startDate,
              endDate: endDate,
            });
        } else {
          await this.saveRatio({
            materialCode: this.selectedSKU,
            operationValue: this.operationValue,
            startDate: startDate,
            endDate: endDate,
          });
        }
      }
      this.saveConfirm = {
        ...this.saveConfirm,
        status: false,
        data: undefined,
      };

      this.onSaveComplete();
    },
    onSaveDataConfirm() {
      this.saveConfirm = {
        ...this.saveConfirm,
        status: true,
      };
    },
    submitHandler() {
      let self = this;
      setTimeout(function () {
        if (self.$refs.entryForm && self.$refs.entryForm.validate()) {
          self.onSaveDataConfirm();
        }
      });
    },
  },
};
</script>
