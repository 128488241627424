export const GLOBAL_MESSAGE = {
  ERROR: 'something went wrong!',
  CREATE: {
    SUCCESS: 'Create Successfully',
    ERROR: 'Create Failed',
  },
  EDIT: {
    SUCCESS: 'Edit Successfully',
    ERROR: 'Edit Failed',
  },
  DELETE: {
    SUCCESS: 'Delete Successfully',
    ERROR: 'Delete Failed',
  },
  UPDATE: {
    SUCCESS: 'Update Successfully',
    ERROR: 'Update Failed',
  },
  APPROVE: {
    SUCCESS: 'Approve Successfully',
    ERROR: 'Approve Failed',
  },
  REJECT: {
    SUCCESS: 'Reject Successfully',
    ERROR: 'Reject Failed',
  },
  UPLOAD: {
    SUCCESS: 'Upload Successfully',
    ERROR: 'Unable to upload file',
  },
}

export const LOGIN_MESSAGE = {
  SUCCESS: 'Login Successfully',
  ERROR: 'Login Failed',
}