import { get } from "lodash";
import { REQUEST_GET, REQUEST_PUT } from "./API_Request";
import { AlertMessage, errorMessage } from "@/plugins/common";
import { GLOBAL_MESSAGE } from "@/plugins/message";
export const state = {
  submittingScores: {
    summaryPointSendDay: null,
    summaryPointSendMode: "",
    error: false,
    errorMessage: "",
  },
};

export const mutations = {
  async GET_SUMMARY_CONFIG_MODE(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET("/api/summarypoints/config-mode");
      if (res.data && res.data.ok) {
        state.submittingScores.summaryPointSendDay =
          res.data.result?.summaryPointSendDay;
        state.submittingScores.summaryPointSendMode =
          res.data.result?.summaryPointSendMode;
      } else {
        state.summaryPointSendDay = {
          ...state.summaryPointSendDay,
          summaryPointSendDay: null,
          summaryPointSendMode: "",
        };
      }
      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async SAVE_SUMMARY_CONFIG_MODE(state, data) {
    data.toggleLoading();
    try {
      
      const res = await REQUEST_PUT(
        "/api/summarypoints/config-update",
        data.form
      );

      if (get(res, "status") === 200) {
        if (res.data?.ok) {
          state.submittingScores.error = false;
          state.submittingScores.errorMessage = "";
          data.toggleLoading();
          const alert = new AlertMessage(
            GLOBAL_MESSAGE.UPLOAD.SUCCESS,
            "ok",
            "success"
          );
          data.toggleAlert(alert);
        } else {
          state.submittingScores.error = true;
          state.submittingScores.errorMessage = res.data?.error;
          data.toggleLoading();

          let alert = new AlertMessage(res.data?.error, "error", "error");
          data.toggleAlert(alert);
        }
        return state;
      }
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
};

export const actions = {
  async getSummaryPointsConfig(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_SUMMARY_CONFIG_MODE(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },

  async saveSummaryPointsConfig(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.SAVE_SUMMARY_CONFIG_MODE(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
};
export const getters = {
  submittingScores(state) {
    return state.submittingScores;
  },
};
export const submittingScores = {
  state,
  mutations,
  actions,
  getters,
};
