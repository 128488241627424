import { REQUEST_GET, REQUEST_POST, REQUEST_PUT } from "./API_Request";
import { AlertMessage, errorMessage, jsonToForm } from "@/plugins/common";
import { GLOBAL_MESSAGE } from "@/plugins/message";
import { get } from "lodash";

export const state = {
  approvedTargets: {
    data: [],
    total: 0,
    perPage: 50,
    pageCount: 1,
  },
  rejectedTargets: {
    data: [],
    total: 0,
    fileName: "",
    remark: "",
    dateReject: "",
    status: "",
    perPage: 50,
    pageCount: 1,
  },
  waitingTargets: {
    data: [],
    total: 0,
    fileId: null,
    perPage: 50,
    pageCount: 1,
  },
  manageTarget: {
    data: [],
    total: 0,
    fileId: null,
    perPage: 50,
    pageCount: 1,
    page: 1,
  },
  uploadTarget: {
    data: [],
    total: 0,
    isSuccess: false,
    error: "",
  },
};

export const mutations = {
  async GET_ADMIN_TARGETS(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET("/api/targets/waiting", data.form);

      if (res?.data?.data !== undefined && res?.data?.data.length > 0) {
        state.waitingTargets.data = res?.data?.data || [];
        state.waitingTargets.total = res?.data?.total || 0;
        state.waitingTargets.fileId = res?.data?.data[0]?.fileId;
        state.waitingTargets.perPage = res?.data?.perPage || 50;
        state.waitingTargets.pageCount = res?.data?.lastPage || 1;
      } else {
        state.waitingTargets = {
          ...state.waitingTargets,
          data: [],
          total: 0,
          fileId: null,
          pageCount: 1,
          perPage: 50,
        };
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      return error;
    }
  },

  async GET_TBR_TARGETS(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET("/api/targets/tbr/waiting", data.form);

      if (res?.data?.data !== undefined && res?.data?.data.length > 0) {
        state.waitingTargets.data = res?.data?.data || [];
        state.waitingTargets.total = res?.data?.total || 0;
        state.waitingTargets.fileId = res?.data?.data[0]?.fileId;
        state.waitingTargets.perPage = res?.data?.perPage || 50;
        state.waitingTargets.pageCount = res?.data?.lastPage || 1;
      } else {
        state.waitingTargets = {
          ...state.waitingTargets,
          data: [],
          total: 0,
          fileId: null,
          pageCount: 1,
          perPage: 50,
        };
      }
      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },

  async GET_MM_TARGETS(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET("/api/targets/mm/waiting", data.form);
      if (res?.data?.data !== undefined && res?.data?.data.length > 0) {
        state.waitingTargets.data = res?.data?.data || [];
        state.waitingTargets.total = res?.data?.total || 0;
        state.waitingTargets.fileId = res?.data?.data[0]?.fileId;
        state.waitingTargets.perPage = res?.data?.perPage || 50;
        state.waitingTargets.pageCount = res?.data?.lastPage || 1;
      } else {
        state.waitingTargets = {
          ...state.waitingTargets,
          data: [],
          total: 0,
          fileId: null,
          perPage: 50,
          pageCount: 1,
        };
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_MM_TARGETS_DONE(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET("/api/targets/approved", data.form);
      if (res?.data?.data !== undefined && res?.data?.data.length) {
        state.waitingTargets.data = res?.data?.data || [];
        state.waitingTargets.total = res?.data?.total || 0;
        state.waitingTargets.fileId = res?.data?.data[0]?.fileId;
        state.waitingTargets.perPage = res?.data?.perPage || 50;
        state.waitingTargets.pageCount = res?.data?.lastPage || 1;
      } else {
        state.waitingTargets = {
          ...state.waitingTargets,
          data: [],
          total: 0,
          fileId: null,
          perPage: 50,
          pageCount: 1,
        };
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_REJECTED_TARGETS(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET("/api/targets/rejected", data.form);

      if (res?.data?.data != undefined && res?.data?.data.length > 0) {
        state.rejectedTargets.data = res?.data?.data || [];
        state.rejectedTargets.total = res?.data?.total || 0;
        state.rejectedTargets.fileName = res?.data?.data[0]?.fileName || "";
        state.rejectedTargets.remark = res?.data?.data[0]?.remark || "";
        state.rejectedTargets.dateReject = res?.data?.data[0]?.dateReject || "";
        state.rejectedTargets.status = res?.data?.data[0]?.status || "";
        state.rejectedTargets.perPage = res?.data?.perPage || 50;
        state.rejectedTargets.pageCount = res?.data?.lastPage || 1;
      } else {
        state.rejectedTargets = {
          ...state.rejectedTargets,
          data: [],
          total: 0,
          fileName: "",
          remark: "",
          dateReject: "",
          status: "",
          perPage: 50,
          pageCount: 1,
        };
      }
      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_APPROVED_TARGETS(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET("/api/targets/approved", data.form);

      if (res?.data?.data && res?.data?.data.length > 0) {
        state.approvedTargets.data = res?.data?.data || [];
        state.approvedTargets.total = res?.data?.total || 0;
        state.approvedTargets.perPage = res?.data?.perPage || 50;
        state.approvedTargets.pageCount = res?.data?.lastPage || 1;
      } else {
        state.approvedTargets = {
          ...state.approvedTargets,
          data: [],
          total: 0,
          perPage: 50,
          pageCount: 1,
        };
      }
      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async APPROVE_TBR_TARGETS(state, data) {
    data.toggleLoading();
    try {
      let body = {
        targetUploadId: state.waitingTargets.fileId,
      };
      await REQUEST_PUT(`/api/targets/tbr/approve`, body);
      data.toggleLoading();

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.APPROVE.SUCCESS,
        "ok",
        "success"
      );
      data.toggleAlert(alert);
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.APPROVE.ERROR,
        "error",
        "error"
      );
      data.toggleAlert(alert);
      return error;
    }
  },
  async REJECT_TBR_TARGETS(state, data) {
    data.toggleLoading();
    try {
      let body = {
        targetUploadId: state.waitingTargets?.fileId,
        remark: data?.form?.remark,
      };
      await REQUEST_PUT(`/api/targets/tbr/reject`, body);
      data.toggleLoading();

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.REJECT.SUCCESS,
        "ok",
        "success"
      );
      data.toggleAlert(alert);
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.REJECT.ERROR,
        "error",
        "error"
      );
      data.toggleAlert(alert);
      return error;
    }
  },
  async APPROVE_MM_TARGETS(state, data) {
    data.toggleLoading();
    try {
      let body = {
        targetUploadId: state.waitingTargets.fileId,
      };
      await REQUEST_PUT(`/api/targets/mm/approve`, body);
      data.toggleLoading();

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.APPROVE.SUCCESS,
        "ok",
        "success"
      );
      data.toggleAlert(alert);
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.APPROVE.ERROR,
        "error",
        "error"
      );
      data.toggleAlert(alert);
      return error;
    }
  },

  async REJECT_MM_TARGET(state, data) {
    data.toggleLoading();
    try {
      await REQUEST_PUT(`/api/targets/mm/reject`, data.form);
      data.toggleLoading();

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.REJECT.SUCCESS,
        "ok",
        "success"
      );
      data.toggleAlert(alert);
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);

      // Alert
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.REJECT.ERROR,
        "error",
        "error"
      );
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_MANAGE_TARGETS(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET("/api/targets/search", data.form);
      if (res?.data?.data !== undefined && res?.data?.data.length > 0) {
        state.manageTarget.data = res?.data?.data || [];
        state.manageTarget.total = res?.data?.total || 0;
        state.manageTarget.perPage = res?.data?.perPage || 50;
        state.manageTarget.pageCount = res?.data?.lastPage || 1;
      } else {
        state.manageTarget.data = [];
        state.manageTarget.total = 0;
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_REJECTED_TARGETS_LIST(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET("/api/targets/reject-history", data.form);

      if (res?.data?.data != undefined && res?.data?.data.length > 0) {
        state.rejectedTargets.data = res?.data?.data || [];
        state.rejectedTargets.total = res?.data?.total || 0;
        state.rejectedTargets.perPage = res?.data?.perPage || 50;
        state.rejectedTargets.pageCount = res?.data?.lastPage || 1;
      } else {
        state.rejectedTargets = {
          ...state.rejectedTargets,
          data: [],
          total: 0,
          fileName: "",
          remark: "",
          dateReject: "",
          status: "",
          perPage: 50,
          pageCount: 1,
        };
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async UPLOAD_MANAGE_TARGETS(state, data) {
    data.toggleLoading();
    try {
      state.uploadTarget = {
        ...state.uploadTarget,
        total: 0,
        data: [],
        isSuccess: false,
        error: "",
      };
      const res = await REQUEST_POST(
        "/api/targets/upload",
        jsonToForm(data.form)
      );
      if (res?.data?.ok) {
        state.uploadTarget.data = res?.data?.result || [];
        state.uploadTarget.total = res?.data?.result?.length || 0;
      } else {
        state.uploadTarget.data = [];
        state.uploadTarget.error = res?.data?.error;
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.UPLOAD.ERROR,
        "error",
        "error"
      );
      data.toggleAlert(alert);
      return error;
    }
  },
  async CLEAR_TARGET(state, data) {
    data.toggleLoading();
    try {
      state.uploadTarget = {
        ...state.uploadTarget,
        total: 0,
        data: [],
        isSuccess: false,
        error: "",
      };
      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      return error;
    }
  },
  async DELETE_TARGET(state, data) {
    data.toggleLoading();

    var dataIndex = state.uploadTarget.data.findIndex(
      (x) => x.no == data?.row_number
    );
    state.uploadTarget.data[dataIndex].isDelete = true;

    try {
      state.uploadTarget = {
        ...state.uploadTarget,
        data: state.uploadTarget.data,
      };

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.DELETE.ERROR,
        "error",
        "error"
      );
      data.toggleAlert(alert);
      return error;
    }
  },
  async SAVE_TARGETS(state, data) {
    data.toggleLoading();
    try {
      let body = {
        result: state.uploadTarget.data,
      };
      const res = await REQUEST_POST("/api/targets/save", body);

      if (
        get(res, "status") === 201 &&
        res?.data != undefined &&
        res?.data?.ok === true
      ) {
        data.toggleLoading();
        const alert = new AlertMessage(
          GLOBAL_MESSAGE.UPLOAD.SUCCESS,
          "ok",
          "success"
        );
        state.uploadTarget = {
          ...state.uploadTarget,
          isSuccess: true,
        };

        data.toggleAlert(alert);
        return state;
      }
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.UPDATE.ERROR,
        "error",
        "error"
      );
      data.toggleAlert(alert);
      return error;
    }
  },
};

export const actions = {
  async getAdminTargets(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_ADMIN_TARGETS(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getTbrTargets(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_TBR_TARGETS(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getMMTargets(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_MM_TARGETS(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getMMTargetsDone(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_MM_TARGETS_DONE(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getRejectedTargetsList(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_REJECTED_TARGETS_LIST(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getRejectedTargets(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_REJECTED_TARGETS(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getApprovedTargets(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_APPROVED_TARGETS(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async approveTbrTargets(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.APPROVE_TBR_TARGETS(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async rejectTbrTargets(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.REJECT_TBR_TARGETS(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async approveMmTargets(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.APPROVE_MM_TARGETS(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async rejectMmTargets(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.REJECT_MM_TARGETS(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getManageTargets(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_MANAGE_TARGETS(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async UploadManageTargets(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.UPLOAD_MANAGE_TARGETS(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async clearTargets(context) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.CLEAR_TARGET(state, { toggleLoading, toggleAlert });
  },
  async deleteTargets(context, row_number) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.DELETE_TARGET(state, {
      toggleLoading,
      row_number,
      toggleAlert,
    });
  },
  async saveTargets(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.SAVE_TARGETS(state, { toggleLoading, form, toggleAlert });
  },
};

export const getters = {
  // Add Logic Before Computed
  approvedTargets(state) {
    // eslint-disable-line
    return state.approvedTargets;
  },
  rejectedTargets(state) {
    // eslint-disable-line
    return state.rejectedTargets;
  },
  waitingTargets(state) {
    // eslint-disable-line
    return state.waitingTargets;
  },
  manageTarget(state) {
    return state.manageTarget;
  },
  uploadTarget(state) {
    return state.uploadTarget;
  },
};

export const targets = {
  state,
  mutations,
  actions,
  getters,
};
