<template>
  <div class="submitting-scores-container">
    <h2 class="mb-3">ตั้งค่าการส่งคะแนน</h2>
    <Card>
      <v-form ref="submitForm" @submit.prevent="submitHandler">
        <div v-if="errorMessage" class="d-flex flex-column col">
          <h4 class="red--text">*** {{ errorMessage }}</h4>
        </div>
        <div class="col-3">
          <v-radio-group v-model="selectMode">
            <v-radio class="pb-5" label="Manual" value="Manual"></v-radio>
            <v-radio label="Auto" value="Auto"></v-radio>
          </v-radio-group>
        </div>
        <div class="col-3">
          <v-select
            label="วันที่"
            :items="dateList"
            v-model="selectDate"
            outlined
            dense
            background-color="#fff"
            item-text="materialTextName"
            item-value="materialCode"
            return-object
          ></v-select>
        </div>

        <div>
          <v-col class="mr-0 mr-md-4 d-md-block d-none" cols="12" md="auto">
            <v-btn
              class="secondary-btn mr-5"
              width="120"
              height="40"
              color="secondary"
              depressed
              @click="cancelItem"
            >
              Cancel
            </v-btn>
            <v-btn
              width="120"
              height="40"
              color="primary"
              depressed
              type="submit"
            >
              Setting
            </v-btn>
          </v-col>
        </div>
      </v-form>
    </Card>
    <ConfirmModal
      v-model="cancelConf.status"
      :data="cancelConf"
      @confirm="clearData"
    />
    <ConfirmModal
      v-model="saveConfirm.status"
      :data="saveConfirm"
      @confirm="onSaveSaveData"
    />
  </div>
</template>
<script>
import Card from "@/components/Card";
import Utils from "@/mixin";
import ConfirmModal from "@/components/ConfirmModal";
export default {
  name: "Submitting-Scores",
  mixins: [Utils],
  components: {
    Card,
    ConfirmModal,
  },
  data() {
    return {
      errorMessage: "",
      dateList: [],
      selectDate: 1,
      selectMode: "Manual",
      cancelConf: {
        title: "ยืนยันการยกเลิกข้อมูล",
        status: false,
      },
      saveConfirm: {
        title: "ยืนยันการบันทึกข้อมูล",
        status: false,
      },
    };
  },

  methods: {
    getDateList() {
      this.dateList = [];
      for (let i = 1; i <= 28; i++) {
        this.dateList.push(i);
      }
    },
    async getInitialData() {
      this.errorMessage = "";
      const fetchSummaryPointsConfigData = () => this.getSummaryPointsConfig();
      await fetchSummaryPointsConfigData();
      const { submittingScores } = this;
      if (submittingScores) {
        this.selectDate = submittingScores.summaryPointSendDay;
        this.selectMode = submittingScores.summaryPointSendMode;
      }
    },
    cancelItem() {
      this.cancelConf = {
        ...this.cancelConf,
        status: true,
      };
    },
    async clearData(value = false) {
      if (value) {
        this.$refs.form && this.$refs.form.reset();
        this.getInitialData();
      }

      this.cancelConf = {
        ...this.cancelConf,
        status: false,
      };
    },
    submitHandler() {
      let self = this;
      setTimeout(function () {
        if (self.$refs.submitForm.validate()) {
          self.onSaveDataConfirm();
        }
      });
    },
    onSaveDataConfirm() {
      this.saveConfirm = {
        ...this.saveConfirm,
        status: true,
      };
    },
    async onSaveSaveData(value) {
      this.$refs.form && this.$refs.form.reset();

      if (value) {
        await this.saveSummaryPointsConfig({
          summaryPointSendMode: this.selectMode,
          summaryPointSendDay: this.selectDate,
        });
      }

      this.saveConfirm = {
        ...this.saveConfirm,
        status: false,
      };
      const { submittingScores } = this;
      if (submittingScores.error) {
        this.errorMessage = submittingScores.errorMessage;
      } else {
        this.getInitialData();
      }
    },
  },
  mounted() {
    this.getDateList();
    this.getInitialData();
  },
};
</script>
<style lang="scss">
.submitting-scores-container {
  padding: 40px 25px;
}
.secondary-btn {
  color: #000 !important;
}
</style>
