<template>
  <div>
    <div v-if="isDisplaySubTap">
      <SwitchTab :subTap="subTap" :onClickChangeSubTap="onClickChangeSubTap" />
    </div>
    <div v-if="page === 'reject' && summaryTitle.fileName !== ''">
      <div>
        <v-btn depressed @click="onClickBack">
          <v-icon>mdi-arrow-left</v-icon> Back
        </v-btn>
        <RejectTitle
          :fileName="summaryTitle.fileName"
          :status="summaryTitle.status"
          :dateReject="summaryTitle.dateReject"
          :remark="summaryTitle.remark"
        />
      </div>
    </div>
    <div v-if="currentData != undefined && currentData.length > 0" class="mt-3">
      <div v-for="(item, index) in currentData" :key="index">
        <PointSummaryItems
          :shopName="item.shopName"
          :shopId="item.shopId ? item.shopId.toString() : '-'"
          :status="
            item.items && item.items.length > 0 ? item.items[0].status : ''
          "
          :headers="headers"
          :dataRow="item.items"
        />
      </div>
    </div>
    <div v-if="dataList && dataList.length > 0">
      <div class="mt-5 mb-5">
        {{ currentStore }} จาก {{ dataList.length }} ร้าน
      </div>
      <v-pagination
        v-model="currentStore"
        :length="dataList.length"
        color="primary"
      />
    </div>
    <div class="d-flex justify-center mt-3" v-else>
      <h3>ไม่พบข้อมูล</h3>
    </div>
    <div
      v-if="
        this.isDisplayConfirm && dataList !== undefined && dataList.length > 0
      "
      class="text-right mr-0 mr-md-4 mt-3"
    >
      <v-btn
        class="mr-5"
        width="150"
        height="40"
        color="error"
        depressed
        @click="onClickReject"
      >
        Reject
      </v-btn>
      <v-btn
        width="150"
        height="40"
        color="primary"
        depressed
        @click="onClickApprove"
      >
        Approve
      </v-btn>
    </div>
    <div v-if="rejectConfirm">
      <ConfirmModal
        v-model="rejectConfirm.status"
        :data="rejectConfirm"
        :enableTextArea="true"
        @confirm="onClickRejectData"
      />
    </div>
    <div v-if="saveConfirm">
      <ConfirmModal
        v-model="saveConfirm.status"
        :data="saveConfirm"
        @confirm="onClickApproveData"
      />
    </div>
  </div>
</template>
<script>
import PointSummaryItems from "@/components/PointSummaryItems";
import SwitchTab from "@/components/SwitchTab.vue";
import ConfirmModal from "@/components/ConfirmModal";
import RejectTitle from "@/components/RejectTitle.vue";
export default {
  components: {
    PointSummaryItems,
    SwitchTab,
    ConfirmModal,
    RejectTitle,
  },

  props: {
    currentStore: {
      type: Number,
      default: 1,
    },
    subTap: {
      type: Number,
      default: 1,
    },
    isDisplayStatus: { type: Boolean, default: false },
    page: {
      type: String,
    },
    summaryTitle: {
      type: Object,
      default: () => {},
    },
    headers: { type: Array, default: () => [] },
    dataList: { type: Array, default: () => [] },
    isDisplaySubTap: {
      type: Boolean,
      default: false,
    },
    onClickChangeSubTap: {
      type: Function,
      default: () => {},
    },
    isDisplayConfirm: {
      type: Boolean,
      default: false,
    },
    onClickReject: {
      type: Function,
      default: () => {},
    },
    onClickApprove: {
      type: Function,
      default: () => {},
    },
    rejectConfirm: {
      type: Object,
      default: () => {},
    },
    saveConfirm: {
      type: Object,
      default: () => {},
    },
    onClickRejectData: {
      type: Function,
      default: () => {},
    },
    onClickApproveData: {
      type: Function,
      default: () => {},
    },
    onClickBack: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    currentData() {
      let result = [];
      let dataDetailList = this.dataList;

      if (dataDetailList.length > 0) {
        result.push(dataDetailList[this.currentStore - 1]);
      }
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return result;
    },
  },
};
</script>
