<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
    location="bottom"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        :label="label"
        outlined
        background-color="#fff"
        dense
        v-bind="attrs"
        v-on="on"
        readonly
        required
        :rules="required ? rules : []"
        prepend-inner-icon="mdi-calendar-blank-outline"
        :append-icon="isClearDate ? 'mdi-close-circle' : ''"
        @click:append="clearDate"
      >
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :min="min"
      :max="max"
      no-title
      color="primary"
      popover-align="bottom"
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    min: { type: String, default: "" },
    max: { type: String, default: "" },
    label: { type: String, default: "" },
    value: { type: String, default: "" },
    onSelected: {
      type: Function,
      default: () => {},
    },
    required: { type: Boolean, default: false },
    isClearDate: { type: Boolean, default: false },
    onClearDate: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    date: "",
    menu: false,
    dateFormatted: "",
    rules: [
      (v) => !!v || "กรุณาระบุข้อมูล",
      (v) =>
        /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[/]([0]?[1-9]|[1][0-2])[/]([0-9]{4}|[0-9]{2})$/.test(
          v
        ) || "กรุณาวันที่ รูปแบบ วว/ดด/ปปปป",
    ],
  }),

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    value: {
      handler(newValue) {
        if (newValue) {
          this.dateFormatted = this.formatDate(newValue);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      let pickerDate = `${day}/${month}/${year}`;
      this.onSelected(date);
      return pickerDate;
    },
    clearDate() {
      this.date = "";
      this.dateFormatted = "";
      this.onClearDate();
    },
  },
};
</script>
