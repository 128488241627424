<template>
  <Card>
    <v-form>
      <v-row class="mt-3">
        <v-col class="ml-0 ml-md-4" cols="12" md="6" lg="5" xl="4">
          <v-text-field
            v-model="keywordSearch"
            dense
            background-color="#fff"
            outlined
            label="ค้นหา"
          />
        </v-col>

        <v-col class="ml-0 ml-md-4" cols="1">
          <v-btn
            min-width="40"
            height="40"
            color="primary"
            depressed
            @click="onSearchKey"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
        <v-col v-if="dateSentPoint" class="mt-3">
          <h3 style="font-style: italic">
            *ระบบจะส่งคะแนนขออนุมัติทุกวันที่ {{ dateSentPoint }} ของเดือน
          </h3>
        </v-col>
      </v-row>
    </v-form>
    <div v-if="dataList !== undefined && dataList.length > 0">
      <v-data-table
        class="mx-0 mx-md-4"
        :loading="isLoading"
        :headers="headers"
        :page.sync="page"
        :items-per-page="perPage"
        :items="dataList"
        hide-default-footer
        :page-count="pageCount"
        no-data-text="ไม่พบข้อมูล"
        no-results-text="ไม่พบข้อมูล"
      >
        <template v-slot:item.action="{ item }">
          <v-btn
            width="120"
            height="40"
            color="primary"
            depressed
            @click="reviewItem(item)"
          >
            Review
          </v-btn>
        </template>
      </v-data-table>
      <Paginations
        :page="page"
        :pageCount="pageCount"
        :total="dataTotal"
        :itemsPerPage="perPage"
        :itemsPerPageOptions="perPageOptions"
        :onChangePage="onChangePage"
      />
    </div>
    <div class="d-flex justify-center mt-3" v-else>
      <h3>ไม่พบข้อมูล</h3>
    </div>
  </Card>
</template>
<script>
import Card from "@/components/Card";
import Utils from "@/mixin";
import Paginations from "@/components/Paginations";

export default {
  mixins: [Utils],
  components: {
    Card,
    Paginations,
  },
  props: {
    page: { type: Number, default: 1 },
    dataTotal: { type: Number, default: 1 },
    pageCount: { type: Number, default: 1 },
    perPage: { type: Number, default: 25 },
    dateSentPoint: { type: Number, default: null },
    dataList: { type: Array, default: () => [] },
    headers: { type: Array, default: () => [] },
    perPageOptions: { type: Array, default: () => [] },
    onReviewItem: { type: Function, default: () => {} },
    onSearch: { type: Function, default: () => {} },
    onChangePage: { type: Function, default: () => {} },
  },
  data: () => ({
    keywordSearch: "",
  }),
  methods: {
    onSearchKey() {
      this.onSearch(this.keywordSearch);
    },
    reviewItem(item) {
      this.onReviewItem(item);
    },
  },
};
</script>
<style>
.text-auto {
  font-style: italic;
}
</style>
