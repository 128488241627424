<template>
  <div>
    <div class="mt-3 d-flex flex-row">
      <div class="col pl-0">
        <h4>ชื่อไฟล์ : {{ fileName }}</h4>
      </div>

      <MyStatus class="col" :status="status" variant="reject" />

      <div class="col d-flex flex-row">
        <div class="mx-5"><v-icon>mdi-file-document-outline</v-icon></div>
        <div>
          <h4>Date Rejected : {{ dateReject }}</h4>
        </div>
      </div>
    </div>
    <div v-if="remark" class="mt-3 d-flex flex-column box-remark">
      <div><h5>หมายเหตุ :</h5></div>
      <div>
        <span class="text-remark">{{ remark }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import MyStatus from "@/components/MyStatus";
export default {
  name: "Reject-Title",
  components: {
    MyStatus,
  },
  props: {
    fileName: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
    dateReject: {
      type: String,
      default: "",
    },
    remark: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    subMenu: [
      { id: 1, title: "To Do" },
      { id: 2, title: "Done" },
    ],
  }),
};
</script>
<style lang="scss">
$light-red: #f7d1cd;
$red: #ff0000;
.text-title-status {
  background: $light-red;
  color: $red;
  font-weight: bold;
  border-radius: 50px;
  height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 2px;
  padding-right: 10px;
  text-align: center;
}
.text-status {
  max-width: max-content;
}
.text-remark {
  font-size: 0.83em;
}
.box-remark {
  border: 1px solid #f1eeee;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 0px 6px 3px #f1eeee;
}
</style>
