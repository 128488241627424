import { REQUEST_GET } from "./API_Request";
import { errorMessage,AlertMessage } from "@/plugins/common";
import { GLOBAL_MESSAGE } from "@/plugins/message";
export const state = {
  materialMastersList: [],
};

export const mutations = {
  async GET_MATERIAL_MASTER_LIST(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET("/api/materialmasters", data.form);
       if (res?.data?.data && res?.data?.data.length > 0)
       {
           state.materialMastersList = res?.data?.data;
       }
       
      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
};
export const actions = {
  async getMaterialMastersList(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_MATERIAL_MASTER_LIST(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
};
export const getters = {
  materialMastersList(state) {
    return state.materialMastersList;
  },
};
export const materialMasters = {
  state,
  mutations,
  actions,
  getters,
};
