var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-5"},[_c('h3',{staticClass:"title-table"},[_vm._v(" ร้าน "+_vm._s(_vm.shopName)+" (รหัสร้าน : "+_vm._s(_vm.shopId)+" ) ")])]),(_vm.status)?_c('div',[_c('MyStatus',{attrs:{"status":_vm.status,"variant":_vm.status !== '' && _vm.status.toLocaleLowerCase().indexOf('reject') >= 0
            ? 'reject'
            : 'accept'}})],1):_vm._e(),(_vm.dateTRP)?_c('div',{staticClass:"mr-5"},[_c('h3',{staticClass:"title-table"},[_vm._v("Date TBR Approved : "+_vm._s(_vm.dateTRP))])]):_vm._e(),(_vm.dateMM)?_c('div',{staticClass:"mr-5"},[_c('h3',{staticClass:"title-table"},[_vm._v("Date MM Approved : "+_vm._s(_vm.dateMM))])]):_vm._e()]),_c('div',{staticClass:"d-flex flex-row list-summary mt-3"},_vm._l((_vm.headers),function(item,index){return _c('div',{key:index,class:item.class},[_c('span',{staticClass:"title-summary"},[_vm._v(_vm._s(item.text))])])}),0),_vm._l((_vm.dataRow),function(itemRow,index){return _c('div',{key:`row-` + index},[_c('div',{staticClass:"d-flex flex-row list-summary mt-1"},_vm._l((_vm.headers),function(itemHeader,index){return _c('div',{key:`header-item` + index,class:itemHeader.class},[(
            itemHeader.dataType === 'number' &&
            itemHeader.dataType !== undefined
          )?_c('span',{staticClass:"row-summary"},[_vm._v(_vm._s(_vm.$numberFormat(itemRow[itemHeader.value])))]):_c('span',{staticClass:"row-summary"},[_vm._v(_vm._s(itemRow[itemHeader.value]))])])}),0),_c('div',{staticClass:"d-flex flex-row list-detail-summary mt-1"},[_vm._m(0,true),_c('div',{staticClass:"col-2 text-right"},[_c('span',{staticClass:"detail-summary"},[_vm._v(" "+_vm._s(_vm.$numberFormat(itemRow.target)))])])]),_c('div',{staticClass:"d-flex flex-row list-detail-summary mt-1"},[_vm._m(1,true),_c('div',{staticClass:"col-2 text-right"},[_c('span',{staticClass:"detail-summary"},[_vm._v(" "+_vm._s(_vm.$numberFormat(itemRow.specialPointStep1)))])])]),_c('div',{staticClass:"d-flex flex-row list-detail-summary mt-1"},[_vm._m(2,true),_c('div',{staticClass:"col-2 text-right"},[_c('span',{staticClass:"detail-summary"},[_vm._v(" "+_vm._s(_vm.$numberFormat(itemRow.specialPointStep2)))])])]),_c('div',{staticClass:"d-flex flex-row list-detail-summary mt-1 mb-3"},[_vm._m(3,true),_c('div',{staticClass:"col-2 text-right"},[_c('span',{staticClass:"detail-summary"},[_vm._v(" "+_vm._s(_vm.$numberFormat(itemRow.summary)))])])])])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5"},[_c('span',{staticClass:"detail-summary"},[_vm._v(" Target")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-7"},[_c('span',{staticClass:"detail-summary"},[_vm._v(" Special Point (Step 1)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-7"},[_c('span',{staticClass:"detail-summary"},[_vm._v(" Special Point (Step 2)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-7"},[_c('span',{staticClass:"detail-summary"},[_vm._v(" Summary")])])
}]

export { render, staticRenderFns }