import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'

import DefaultLayout from '@/layouts/default.vue'
import HomeLayout from '@/layouts/homeLayout.vue'
import AuthLayout from '@/layouts/authLayout.vue'

import '@/assets/global.scss'

Vue.component('defaultLayout', DefaultLayout)
Vue.component('homeLayout', HomeLayout)
Vue.component('authLayout', AuthLayout)

Vue.config.productionTip = false

Vue.prototype.$numberFormat = (number) =>
  new Intl.NumberFormat('en-US').format(number)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
