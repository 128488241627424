<template>
  <div class="waiting-container">
    <h2 class="mb-3">Waiting for Approval</h2>
    <TabMenu
      :tap="tap"
      v-bind:onClick="onClickChangeTap"
      :onSearchKey="onSearchKey"
    />
    <div v-if="tap === 'transaction'">
      <TransactionList
        :subTap="subTap"
        page="waiting"
        :total="transactionsTotal"
        :currentPage="page"
        :perPage="perPage"
        :pageCount="pageCount"
        :isDisplaySubTap="!admin"
        :onClickChangeSubTap="onClickChangeSubTap"
        :headers="headersTransaction"
        :dataList="transactionList"
        :isDisplayConfirm="!admin && subTap === 1"
        :onClickRejectData="rejectData"
        :onClickApproveData="approveData"
        :rejectConfirm="rejectConfirm"
        :saveConfirm="saveConfirm"
        :onClickReject="onClickReject"
        :onClickApprove="onClickApprove"
        :onChangePage="onChangePage"
      />
    </div>
    <div v-else-if="tap === 'summaryPoint'">
      <SummaryPointList
        :subTap="subTap"
        :currentStore="currentStore"
        page="waiting"
        :isDisplayStatus="true"
        :headers="headersSummary"
        :dataList="summaryList"
        :isDisplaySubTap="!admin"
        :isDisplayConfirm="!admin && subTap === 1"
        :onClickRejectData="rejectData"
        :onClickApproveData="approveData"
        :rejectConfirm="rejectConfirm"
        :saveConfirm="saveConfirm"
        :onClickReject="onClickReject"
        :onClickApprove="onClickApprove"
        :onClickChangeSubTap="onClickChangeSubTap"
      />
    </div>

    <div v-else-if="tap === 'target'">
      <h4 class="red--text">{{ errorSearch }}</h4>
      <TargetList
        :subTap="subTap"
        page="waiting"
        :total="targetTotal"
        :currentPage="page"
        :perPage="perPage"
        :pageCount="pageCount"
        :headers="headersTarget"
        :dataList="targetList"
        :isDisplaySubTap="!admin"
        :isDisplayConfirm="!admin && subTap === 1"
        :onClickRejectData="rejectData"
        :onClickApproveData="approveData"
        :onClickChangeSubTap="onClickChangeSubTap"
        :rejectConfirm="rejectConfirm"
        :saveConfirm="saveConfirm"
        :onClickReject="onClickReject"
        :onClickApprove="onClickApprove"
        :onChangePage="onChangePage"
      />
    </div>
  </div>
</template>
<script>
import TargetList from "@/components/TargetList";
import TransactionList from "@/components/TransactionList";
import SummaryPointList from "@/components/SummaryPointList";
import TabMenu from "@/components/TabMenu";
import { getJWTToken, PERMISSION } from "@/plugins/auth";
import Utils from "@/mixin";
import { reduce } from "lodash";
export default {
  mixins: [Utils],
  name: "Waiting-for-Approval",
  components: {
    TargetList,
    TabMenu,
    TransactionList,
    SummaryPointList,
  },
  computed: {
    targetList() {
      const { waitingTargets } = this;
      const result = reduce(
        waitingTargets.data,
        (a, b) => {
          a.push({
            ...b,
          });
          return a;
        },
        []
      );

      return result;
    },
    transactionList() {
      const { waitingTransactions } = this;
      const result = reduce(
        waitingTransactions.data,
        (a, b) => {
          a.push({
            ...b,
          });
          return a;
        },
        []
      );
      return result;
    },
    summaryList() {
      const { waitingSummaries } = this;
      const result = reduce(
        waitingSummaries.data,
        (a, b) => {
          b.items.map((a, index) => {
            a.no = index + 1;
          });
          a.push({
            ...b,
          });
          return a;
        },
        []
      );
      return result;
    },
    targetTotal() {
      const { waitingTargets } = this;
      return waitingTargets.total;
    },
    transactionsTotal() {
      const { waitingTransactions } = this;
      return waitingTransactions.total;
    },

    perPage() {
      let perPage = 50;
      if (this.tap === "transaction") {
        const { waitingTransactions } = this;
        perPage = waitingTransactions.perPage;
      } else if (this.tap === "summaryPoint") {
        perPage = 50;
      } else if (this.tap === "target") {
        const { waitingTargets } = this;
        perPage = waitingTargets.perPage;
      }
      return perPage;
    },
    pageCount() {
      let pageCount = 1;
      if (this.tap === "transaction") {
        const { waitingTransactions } = this;

        pageCount = waitingTransactions.pageCount;
      } else if (this.tap === "summaryPoint") {
        pageCount = 1;
      } else if (this.tap === "target") {
        const { waitingTargets } = this;
        pageCount = waitingTargets.pageCount;
      }

      return pageCount;
    },
  },
  created() {
    this.jwtToken = getJWTToken();
    if (this.jwtToken?.role) {
      const { role } = this.jwtToken;
      this.admin = role === PERMISSION.TBR_ADMIN ? true : false;
      this.tbrApprove = role === PERMISSION.TBR_APPROVER ? true : false;
      this.mmApprove = role === PERMISSION.MM_APPROVER ? true : false;
    }
  },
  data: () => ({
    tap: "transaction",
    subTap: 1,
    page: 1,
    jwtToken: null,
    currentStore: 1,
    tbrApprove: false,
    mmApprove: false,
    admin: false,
    keyword: "",
    errorSearch: "",
    headersTarget: [
      { text: "No", value: "no", sortable: false, dataType: "number" },
      { text: "เบอร์โทร", value: "phone", sortable: false },
      { text: "SKU", value: "sku", sortable: false },
      { text: "Target Month", value: "targetMonth", sortable: false },
      { text: "Target Year", value: "targetYear", sortable: false },
      {
        text: "Target Amount",
        value: "targetAmount",
        sortable: false,
        dataType: "number",
      },
      { text: "Status", value: "status", sortable: false, dataType: "status" },
    ],
    headersTransaction: [
      { text: "No", value: "no", sortable: false, dataType: "number" },
      { text: "เลขที่เอกสาร", value: "documentNo", sortable: false },
      { text: "เบอร์โทร", value: "phone", sortable: false },
      { text: "SKU", value: "sku", sortable: false },
      {
        text: "จำนวน SKU",
        value: "qty",
        sortable: false,
        dataType: "number",
      },
      { text: "Date Create", value: "createdAt", sortable: false },
      { text: "Status", value: "status", sortable: false, dataType: "status" },
    ],
    headersSummary: [
      { text: "ลำดับ", value: "no", sortable: false, class: "col-1" },
      { text: "เบอร์โทรศัพท์", value: "phone", class: "col-2 text-center" },
      { text: "SKU", value: "sku", class: "col-2 text-center" },
      {
        text: "จำนวน SKU",
        value: "qty",
        sortable: false,
        class: "col-2 text-right",
        dataType: "number",
      },
      {
        text: "คะแนนที่ได้",
        value: "point",
        sortable: false,
        class: "col-2 text-right",
        dataType: "number",
      },
      { text: "Date Create", value: "createdAt", class: "col-3 text-center" },
    ],
    rejectConfirm: {
      title: "ยืนยันการยกเลิกข้อมูล",
      remark: "โปรดระบุเหตุผลการยกเลิกข้อมูล",
      status: false,
    },
    saveConfirm: {
      title: "ยืนยันการอนุมัติข้อมูล",
      status: false,
    },
  }),
  methods: {
    onClickChangeTap(value) {
      this.tap = value;
      this.page = 1;
      this.keyword = "";
      this.subTap = 1;

      this.getInitialData();
    },
    onClickChangeSubTap(value) {
      this.subTap = value;
      this.page = 1;
      this.getInitialData();
    },
    async getInitialData() {
      const { jwtToken } = this;
      if (jwtToken?.role) {
        const { role } = jwtToken;

        let condition = {};
        condition.query = this.keyword ? this.keyword : "";
        condition.limit = this.perPage ? this.perPage : 50;
        condition.page = this.page ? this.page : 1;

        if (this.tap === "transaction") {
          const fetchTransaction = {
            [PERMISSION.TBR_ADMIN]: () =>
              this.getTbrAdminTransactions(condition),
            [PERMISSION.TBR_APPROVER]: () =>
              this.subTap === 1
                ? this.getTbrTransactions(condition)
                : this.getMMTransactions(condition),
            [PERMISSION.MM_APPROVER]: () =>
              this.subTap === 1
                ? this.getMMTransactions(condition)
                : this.getMMTransactionsDone(condition),
          };

          const fetchTransactionData =
            fetchTransaction?.[role] || (() => Promise.resolve());
          await fetchTransactionData();
        } else if (this.tap === "summaryPoint") {
          const fetchSummaries = {
            [PERMISSION.TBR_ADMIN]: () => this.getAdminSummaries(condition),
            [PERMISSION.TBR_APPROVER]: () =>
              this.subTap === 1
                ? this.getTRPSummaries(condition)
                : this.getMMSummaries(condition),
            [PERMISSION.MM_APPROVER]: () =>
              this.subTap === 1
                ? this.getMMSummaries(condition)
                : this.getMMSummariesDone(condition),
          };
          const fetchSummariesData =
            fetchSummaries?.[role] || (() => Promise.resolve());
          await fetchSummariesData();
        } else if (this.tap === "target") {
          const fetchTargets = {
            [PERMISSION.TBR_ADMIN]: () => this.getAdminTargets(condition),
            [PERMISSION.TBR_APPROVER]: () =>
              this.subTap === 1
                ? this.getTbrTargets(condition)
                : this.getMMTargets(condition),
            [PERMISSION.MM_APPROVER]: () =>
              this.subTap === 1
                ? this.getMMTargets(condition)
                : this.getMMTargetsDone(condition),
          };

          const fetchTargetData =
            fetchTargets?.[role] || (() => Promise.resolve());
          await fetchTargetData();
        }
      }
    },
    onSearchKey(value) {
      if (value) {
        let isPass = /^[\u0E00-\u0E7FA-Za-z0-9 -]+$/.test(value.trim());
        if (isPass) {
          this.keyword = value.trim();
        } else {
          this.errorSearch = "ไม่สามารถค้นหาได้ เนื่องจากระบุข้อความไม่ถูกต้อง";
          return;
        }
      } else {
        this.keyword = "";
      }
      this.currentStore = 1;
      this.page = 1;
      this.getInitialData();
    },
    onClickReject() {
      this.rejectConfirm = {
        ...this.rejectConfirm,
        status: true,
      };
    },

    onClickApprove() {
      this.saveConfirm = {
        ...this.saveConfirm,
        status: true,
      };
    },
    async rejectData(value = false) {
      if (value) {
        this.$refs.form && this.$refs.form.reset();
        if (this.tap === "transaction") {
          if (this.tbrApprove) {
            await this.rejectTbrTransaction(value);
          } else if (this.mmApprove) {
            await this.rejectMmTransaction(value);
          }
        } else if (this.tap === "summaryPoint") {
          if (this.tbrApprove) {
            await this.rejectTRPSummary(value);
          } else if (this.mmApprove) {
            await this.rejectMMSummary(value);
          }
        } else if (this.tap === "target") {
          await this.rejectTbrTargets(value);
        }
        this.previewFail = false;
      }
      this.rejectConfirm = {
        ...this.rejectConfirm,
        status: false,
        data: undefined,
      };
      this.getInitialData();
    },
    async approveData(value = false) {
      if (value) {
        this.$refs.form && this.$refs.form.reset();
        if (this.tap === "transaction") {
          if (this.tbrApprove) {
            await this.approveTbrTransaction();
          } else if (this.mmApprove) {
            await this.approveMmTransaction();
          }
        } else if (this.tap === "summaryPoint") {
          if (this.tbrApprove) {
            await this.approveTbrSummary(value);
          } else if (this.mmApprove) {
            await this.approveMMSummary(value);
          }
        } else if (this.tap === "target") {
          if (this.tbrApprove) {
            await this.approveTbrTargets();
          } else if (this.mmApprove) {
            await this.approveMmTargets();
          }
        }
        this.previewFail = true;
      }
      this.saveConfirm = {
        ...this.saveConfirm,
        status: false,
      };
      this.keyword = "";
      this.getInitialData();
    },
    onChangePage(value) {
      this.page = value;
      this.getInitialData();
    },
  },

  mounted() {
    window.scrollTo({ top: 0, left: 0 });
    this.getInitialData();
  },
};
</script>

<style lang="scss">
$gray: #d9d9d9;
$green: #79a341;

.waiting-container {
  padding: 40px 25px;
  .filter-btn {
    border: 1px solid $gray;
    &.active {
      color: #000 !important;
      background: $gray;
    }
  }
  .v-pagination {
    @media screen and (max-width: 600px) {
      justify-content: center;
    }
    justify-content: flex-start;
  }
  .secondary {
    color: #000 !important;
  }
}
</style>
