var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"330"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',{staticClass:"pa-2"},[_c('v-card-title',{class:`headline justify-center${_vm.enableTextArea ? '' : ' pa-12'}`},[_c('div',{class:`${_vm.enableTextArea ? 'pt-6' : ''}`},[_vm._v(" "+_vm._s(_vm.data.title || 'ยืนยันการลบข้อมูล?')+" ")]),(_vm.enableTextArea)?_c('v-form',{ref:"form",staticClass:"mt-4",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-textarea',{attrs:{"outlined":"","no-resize":"","rules":[_vm.rules.maxLength],"placeholder":_vm.data.remark || 'กรุณาระบุหมายเหตุ'},model:{value:(_vm.remark),callback:function ($$v) {_vm.remark=$$v},expression:"remark"}})],1):_vm._e()],1),_c('v-card-actions',[_c('v-row',[_c('v-col',[(!_vm.disabledCancel)?_c('v-btn',{staticClass:"cancel-btn",attrs:{"block":"","depressed":"","color":"secondary"},on:{"click":() => {
                _vm.$emit('confirm', false), _vm.$refs.form && _vm.$refs.form.reset()
              }}},[_vm._v(" "+_vm._s(_vm.data.cancelText || 'ยกเลิก')+" ")]):_vm._e()],1),_vm._v(" "+_vm._s(_vm.data.value)+" "),_c('v-col',[_c('v-btn',{attrs:{"block":"","depressed":"","color":"primary","disabled":_vm.enableTextArea && !_vm.valid ? true : false},on:{"click":() => {
                _vm.enableTextArea
                  ? _vm.$emit('confirm', { modal: true, remark: _vm.remark })
                  : _vm.$emit('confirm', true),
                  _vm.$refs.form && _vm.$refs.form.reset()
              }}},[_vm._v(" "+_vm._s(_vm.data.confirmText || 'ยืนยัน')+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }