<template>
  <div class="mt-4 mt-md-6">
    <v-row dense class="ml-0 ml-md-4">
      <v-row dense>
        <v-col cols="12" md="3" class="align-self-center">
          Total Records: {{ total || 0 }}
        </v-col>
        <v-col
          cols="6"
          md="2"
          class="align-self-center text-no-wrap align-center d-flex"
        >
          <div class="mr-2">Transactions per page:</div>
          <v-select
            disabled
            dense
            outlined
            hide-details
            :value="itemsPerPage"
            @change="itemsPerPage = parseInt($event, 10)"
            :items="itemsPerPageOptions"
          />
        </v-col>
        <v-spacer />
      </v-row>
      <v-spacer />
    </v-row>
    <v-row>
      <v-col
        v-if="pageCount > 0"
        cols="12"
        class="pt-auto pt-md-0 ml-0 ml-md-2 align-self-center"
      >
        <v-pagination
          v-model="currentPage"
          :length="pageCount"
          :total-visible="7"
          color="primary"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return { currentPage: 1 };
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    pageCount: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    itemsPerPage: {
      type: Number,
      default: 50,
    },
    itemsPerPageOptions: {
      type: Array,
    },
    onChangePage: {
      type: Function,
      default: () => {},
    },
  },

  watch: {
    currentPage: {
      handler() {
        this.onChangePage(this.currentPage);
      },
      deep: false,
    },
    page: {
      handler(newPage) {
        this.currentPage = newPage;
      },
      deep: false,
    },
  },
};
</script>
