<template>
  <div class="profile-info-container">
    <div class="d-flex align-center">
    <v-img
      v-if="profileData.image"
      :src="profileData.image"
      width="50"
      height="50"
    />
    <v-btn v-else :ripple="false" fab small depressed color="primary">
      <v-icon>mdi-account-outline</v-icon>
    </v-btn>
    <div class="profile-info-content ml-2">
      <h4 class="profile-info-name">{{ profileData.name }}</h4>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    profileData: {
      type: Object,
    },
  },
}
</script>

<style lang="scss">
$gray: #d9d9d9;
$green: #79a341;

.profile-info-container {
  background: $gray;
  border-radius: 6px;
  padding: 15px 20px;
  .v-btn {
    cursor: default;
  }
  .profile-info-content {
    line-height: 1.2;
    .profile-info-name {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
