<template>
  <div class="manage-target-container">
    <v-breadcrumbs :items="breadcrumbs" class="pa-0">
      <template v-slot:divider>
        <v-icon size="x-large"> mdi-chevron-right</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item @click="onClickBreadcrumbs(item.id)">
          <span class="breadcrumbs_item"> {{ item.title }} </span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>

    <div v-if="!isUploadTarget">
      <Card>
        <v-form ref="form">
          <div class="d-flex flex-column flex-md-row">
            <div class="col col-md-3">
              <v-select
                label="Month"
                :items="month"
                v-model="selectedMonth"
                outlined
                dense
                background-color="#fff"
                item-text="text"
                item-value="value"
                return-object
              ></v-select>
            </div>
            <div class="col col-md-3">
              <v-select
                label="Year"
                :items="yearList"
                v-model="selectedYear"
                outlined
                dense
                background-color="#fff"
              ></v-select>
            </div>
            <div class="col col-md-3">
              <v-text-field
                hide-details
                v-model="keyword"
                dense
                background-color="#fff"
                outlined
                label="ค้นหา"
              />
            </div>
            <div class="px-3 py-3">
              <v-btn
                min-width="40"
                height="40"
                color="primary"
                depressed
                @click="onSearchKey"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </div>
            <div class="col">
              <v-btn
                width="120"
                height="40"
                color="primary"
                depressed
                @click="goToUploadTargetPage"
              >
                Upload Target
              </v-btn>
            </div>
          </div>
        </v-form>
        <div v-if="dataList !== undefined && dataList.length > 0">
          <h4 class="red--text">{{ errorSearch }}</h4>
          <TableGroup
            :headers="headers"
            :dataRow="dataList"
            :groupId="'shopId'"
            :groupName="'shopName'"
            :isLoading="isLoading"
            :perPage="perPage"
            :pageCount="pageCount"
          />

          <Paginations
            :page="page"
            :pageCount="pageCount"
            :total="dataTotal"
            :itemsPerPage="perPage"
            :itemsPerPageOptions="itemsPerPageOptions"
            :onChangePage="onChangePage"
          />
        </div>
        <div class="d-flex justify-center mt-3" v-else>
          <h3>ไม่พบข้อมูล</h3>
        </div>
      </Card>
    </div>
    <div v-else>
      <TargetUpload
        :saveConfirm="saveConfirm"
        :onSaveDataConfirm="onSaveDataConfirm"
        :onSaveSaveData="onSaveSaveData"
      />
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { reduce } from "lodash";
import Card from "@/components/Card";
import TargetUpload from "@/components/TargetUpload";
import Paginations from "@/components/Paginations";

import { itemsPerPageOptions } from "@/plugins/common";
import Utils from "@/mixin";
import TableGroup from "@/components/TableGroup.vue";

export default {
  mixins: [Utils],
  name: "ManageTarget",
  components: {
    Card,
    TargetUpload,
    TableGroup,
    Paginations,
  },
  computed: {
    dataList() {
      const { manageTarget } = this;
      const result = reduce(
        manageTarget.data,
        (a, b) => {
          a.push({
            ...b,
          });
          return a;
        },
        []
      );
      return result;
    },
    dataTotal() {
      const { manageTarget } = this;
      return manageTarget.total;
    },
    perPage() {
      const { manageTarget } = this;
      return manageTarget.perPage;
    },
    pageCount() {
      const { manageTarget } = this;
      return manageTarget.pageCount;
    },
    yearList() {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let i = currentYear - 2; i <= currentYear + 2; i++) {
        years.push(i);
      }
      return years;
    },
  },
  data: () => ({
    file: null,
    page: 1,
    isDisplayUpload: false,
    previewFail: false,
    keyword: "",
    selectedMonth: {},
    selectedYear: parseInt(moment().format("YYYY").toString()),
    itemsPerPageOptions: [],
    errorSearch: "",

    conf: {
      status: false,
    },
    cancelConf: {
      title: "ยืนยันการเคลียร์ข้อมูล",
      status: false,
    },
    saveConfirm: {
      title: "ยืนยันการบันทึกข้อมูล",
      status: false,
    },
    headers: [
      { text: "No", value: "no", sortable: false, dataType: "number" },
      { text: "เบอร์โทร", value: "phone", sortable: false },
      { text: "SKU", value: "sku", sortable: false },
      { text: "Target Month", value: "targetMonth", sortable: false },
      { text: "Target Year", value: "targetYear", sortable: false },
      {
        text: "Target Amount",
        value: "targetAmount",
        sortable: false,
        dataType: "number",
      },
      {
        text: "Date Create",
        value: "dateCreate",
        sortable: false,
      },
      { text: "Status", value: "status", sortable: false, dataType: "status" },
    ],
    month: [
      { value: "01", text: "January" },
      { value: "02", text: "February" },
      { value: "03", text: "March" },
      { value: "04", text: "April" },
      { value: "05", text: "May" },
      { value: "06", text: "June" },
      { value: "07", text: "July" },
      { value: "08", text: "August" },
      { value: "09", text: "September" },
      { value: "10", text: "October" },
      { value: "11", text: "November" },
      { value: "12", text: "December" },
    ],
    year: ["2021", "2022", "2023"],
    breadcrumbs: [{ id: "manage-target", title: "Manage Target" }],
    isUploadTarget: false,
  }),
  methods: {
    async getInitialData() {
      //await this.clearPoints();
      if (!this.isUploadTarget) {
        let condition = {};

        if (this.selectedMonth.value !== undefined) {
          condition.month = this.selectedMonth.value;
        }

        if (this.selectedYear) {
          condition.year = this.selectedYear;
        }

        condition.query = this.keyword ? this.keyword : "";

        condition.limit = this.perPage ? this.perPage : 50;

        condition.page = this.page ? this.page : 1;

        const fetchTargetData = () => this.getManageTargets(condition);
        await fetchTargetData();
      }
    },
    onSaveDataConfirm() {
      this.saveConfirm = {
        ...this.saveConfirm,
        status: true,
      };
    },
    async onSaveSaveData(value = false) {
      if (value) {
        this.$refs.form && this.$refs.form.reset();
        await this.saveTargets();
      }
      this.saveConfirm = {
        ...this.saveConfirm,
        status: false,
        data: undefined,
      };
      this.isUploadTarget = false;
      this.getInitialData();
      if (this.breadcrumbs.length > 1) this.breadcrumbs.pop();
    },
    onSearchKey() {
      if (this.keyword) {
        let isPass = /^[\u0E00-\u0E7FA-Za-z0-9 -]+$/.test(this.keyword);
        if (isPass) {
          this.keyword = this.keyword.trim();
          this.errorSearch = "";
        } else {
          this.errorSearch = "ไม่สามารถค้นหาได้ เนื่องจากระบุข้อความไม่ถูกต้อง";
          return;
        }
      } else {
        this.errorSearch = "";
      }
      this.page = 1;
      this.getInitialData();
    },
    deleteItem(data) {
      this.conf = {
        ...this.conf,
        status: true,
        data,
      };
    },
    async deleteData(value = false) {
      const { conf } = this;
      if (value) {
        await this.deletePoint(conf?.data?.row_number);
      }
      this.conf = {
        ...conf,
        status: false,
        data: undefined,
      };
    },

    goToUploadTargetPage() {
      this.isUploadTarget = true;
      this.breadcrumbs.push({ id: "upload-target", title: "Upload Target" });
    },
    onClickBreadcrumbs(id) {
      if (id === "upload-target") {
        this.isUploadTarget = true;
      } else {
        this.isUploadTarget = false;
        if (this.breadcrumbs.length > 1) this.breadcrumbs.pop();
      }
    },
    onChangePage(value) {
      this.page = value;
      this.getInitialData();
    },
    onSelectedYear(value) {
      this.selectedYear = value;
    },
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0 });
    let currentMonth = moment().format("MM").toString();
    let thisMonth = this.month.filter((f) => f.value == currentMonth);
    if (thisMonth.length > 0) this.selectedMonth = thisMonth[0];

    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
};
</script>

<style lang="scss">
.manage-target-container {
  padding: 40px 25px;
  .secondary-btn {
    color: #000 !important;
  }
  .v-pagination {
    @media screen and (max-width: 600px) {
      justify-content: center;
    }
    justify-content: flex-start;
  }
}

.breadcrumbs_item {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  cursor: pointer;
}
</style>
