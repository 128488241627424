<template>
  <div class="d-flex flex-column flex-md-row">
    <div v-for="(item, index) in menu" :key="index">
      <div v-bind:class="index === 0 ? 'col w-100 pl-0' : 'col w-100'">
        <v-btn
          width="170"
          height="40"
          v-bind:color="tap === item.value ? 'primary' : 'secondary'"
          depressed
          @click="onClickTap(item.value)"
        >
          {{ item.title }}
        </v-btn>
      </div>
    </div>
    <div class="col-12 col-md-6 d-flex" v-if="isSearch">
      <div class="col-11 col-md-10 pa-0 mr-md-5">
        <v-text-field
          v-model="keywordSearch"
          dense
          background-color="#fff"
          outlined
          :rules="[rules.validation]"
          label="ค้นหา"
        />
      </div>
      <div class="col-1 col-md-2 pa-0">
        <v-btn
          min-width="40"
          height="40"
          color="primary"
          depressed
          @click="onSearchKey(keywordSearch)"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="col-12 col-md-6 d-flex" v-else></div>
  </div>
</template>
<script>
export default {
  data: () => ({
    keywordSearch: "",
    menu: [
      { id: 1, value: "transaction", title: "Transaction" },
      { id: 2, value: "summaryPoint", title: "Summary Point" },
      { id: 3, value: "target", title: "Target" },
    ],
    rules: {
      validation: (value) => {
        if (value !== "") {
          return (
            /^[\u0E00-\u0E7FA-Za-z0-9 - ]+$/.test(value.trim()) ||
            "กรอกได้เฉพาะตัวเลขและตัวอักษรภาษาอังกฤษและไทยเท่านั้น"
          );
        } else {
          return true;
        }
      },
    },
  }),

  props: {
    tap: {
      type: String,
      default: "transaction",
    },

    onClick: {
      type: Function,
    },
    onSearchKey: {
      type: Function,
      default: () => {},
    },
    isSearch: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClickTap(tapId) {
      this.keywordSearch = "";
      this.onClick(tapId);
    },
  },
};
</script>
