<template>
  <div>
    <div class="d-flex">
      <div class="mr-5">
        <h3 class="title-table">
          ร้าน {{ shopName }} (รหัสร้าน : {{ shopId }} )
        </h3>
      </div>
      <div v-if="status">
        <MyStatus
          :status="status"
          :variant="
            status !== '' && status.toLocaleLowerCase().indexOf('reject') >= 0
              ? 'reject'
              : 'accept'
          "
        />
      </div>

      <div v-if="dateTRP" class="mr-5">
        <h3 class="title-table">Date TBR Approved : {{ dateTRP }}</h3>
      </div>
      <div v-if="dateMM" class="mr-5">
        <h3 class="title-table">Date MM Approved : {{ dateMM }}</h3>
      </div>
    </div>
    <div class="d-flex flex-row list-summary mt-3">
      <div
        v-for="(item, index) in headers"
        :key="index"
        v-bind:class="item.class"
      >
        <span class="title-summary">{{ item.text }}</span>
      </div>
    </div>
    <div v-for="(itemRow, index) in dataRow" :key="`row-` + index">
      <div class="d-flex flex-row list-summary mt-1">
        <div
          v-for="(itemHeader, index) in headers"
          :key="`header-item` + index"
          v-bind:class="itemHeader.class"
        >
          <span
            v-if="
              itemHeader.dataType === 'number' &&
              itemHeader.dataType !== undefined
            "
            class="row-summary"
            >{{ $numberFormat(itemRow[itemHeader.value]) }}</span
          >
          <span v-else class="row-summary">{{
            itemRow[itemHeader.value]
          }}</span>
        </div>
      </div>
      <div class="d-flex flex-row list-detail-summary mt-1">
        <div class="col-5"><span class="detail-summary"> Target</span></div>
        <div class="col-2 text-right">
          <span class="detail-summary">
            {{ $numberFormat(itemRow.target) }}</span
          >
        </div>
      </div>
      <div class="d-flex flex-row list-detail-summary mt-1">
        <div class="col-7">
          <span class="detail-summary"> Special Point (Step 1)</span>
        </div>
        <div class="col-2 text-right">
          <span class="detail-summary">
            {{ $numberFormat(itemRow.specialPointStep1) }}</span
          >
        </div>
      </div>
      <div class="d-flex flex-row list-detail-summary mt-1">
        <div class="col-7">
          <span class="detail-summary"> Special Point (Step 2)</span>
        </div>
        <div class="col-2 text-right">
          <span class="detail-summary">
            {{ $numberFormat(itemRow.specialPointStep2) }}</span
          >
        </div>
      </div>
      <div class="d-flex flex-row list-detail-summary mt-1 mb-3">
        <div class="col-7">
          <span class="detail-summary"> Summary</span>
        </div>
        <div class="col-2 text-right">
          <span class="detail-summary">
            {{ $numberFormat(itemRow.summary) }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyStatus from "@/components/MyStatus";
export default {
  components: { MyStatus },
  props: {
    shopId: { type: String, default: "" },
    shopName: { type: String, default: "" },
    status: { type: String, default: "" },
    dateTRP: { type: String, default: "" },
    dateMM: { type: String, default: "" },
    headers: { type: Array, default: () => [] },
    dataRow: { type: Array, default: () => [] },
  },
};
</script>
<style lang="scss">
$gray: #d9d9d9;
$green: #79a341;
$light-red: #f7d1cd;
$red: #ff0000;

.title-summary {
  font-weight: bold;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}
.row-summary {
  font-weight: 300;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}
.detail-summary {
  font-weight: 300;
  font-size: 0.75rem;
}
.list-summary {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}
.list-detail-summary {
  border-radius: 10px;
  background-color: #d9d9d9;
}
</style>
