import { mapActions, mapGetters } from 'vuex'

//Global computed and methods
export default {
  //Store getters
  computed: {
    ...mapGetters([
      'isLoading',
      'alert',
      'auth',
      'points',
      'approvedTransactions',
      'rejectedTransactions',
      'waitingTransactions',
      'approvedTargets',
      'rejectedTargets',
      'waitingTargets',
      'manageTarget',
      'uploadTarget',
      'materialMastersList',
      'materialRatiosList',
      'manageRatio',
      'summaryReview',
      'summaryReviewDetail',
      'summaryReviewPointDetail',
      'waitingSummaries',
      'rejectedSummaries',
      'approvedSummaries',
      'submittingScores',
      
      
    ]),
  },
  //Store actions
  methods: {
    ...mapActions([
      'toggleLoading',
      'toggleAlertMessage',
      'login',
      'logout',
      'savePoints',
      'uploadPoints',
      'deletePoint',
      'clearPoints',
      'savePoints',
      'getTbrAdminTransactions',
      'getTbrTransactions',
      'getMMTransactions',
      'getRejectedTransactions',
      'getApprovedTransactions',
      'approveTbrTransaction',
      'rejectTbrTransaction',
      'approveMmTransaction',
      'rejectMmTransaction',
      'getAdminTargets',
      'getMMTargets',
      'getManageTargets',
      'UploadManageTargets',
      'clearTargets',
      'deleteTargets',
      'saveTargets',
      'rejectTbrTargets',
      'getRejectedTargets',
      'approveTbrTargets',
      'approveMmTargets',
      'getApprovedTargets',
      'getTbrTargets',
      'getMMTargetsDone',
      'getMMTransactionsDone',
      'getRejectedTransactionsList',
      'getMaterialMastersList',
      'getMaterialRatiosList',
      'saveRatio',
      'clearRatio',
      'getRejectedTargetsList',
      'updateRatio',
      'getSummaryReviewList',
      'getSummaryReviewDetailList',
      'getSummaryReviewDetailPointList',
      'clearSummary',
      'saveSummary',
      'getAdminSummaries',
      'getTRPSummaries',
      'getMMSummaries',
      'getMMSummariesDone',
      'rejectTRPSummary',
      'rejectMMSummary',
      'approveTbrSummary',
      'approveMMSummary',
      'getRejectedSummariesList',
      'getRejectedSummariesDetail',
      'getApprovedSummariesList',
      'getSummaryPointsConfig',
      'saveSummaryPointsConfig'

    ]),
  },
}
