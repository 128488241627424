import jwtDecode from 'jwt-decode'

import router from '@/router'

export const getToken = () => localStorage.getItem('token')

export const getJWTToken = (token = getToken()) => {
  if (token === null) {
    return null
  } else {
    const jwtToken = jwtDecode(token)
    return jwtToken?.u
  }
}

export const redirectAuth = () => {
  const decode = getJWTToken()
  console.log("decode",decode)
  if (decode !== null && decode !== undefined) {
    const { role } = decode
    switch (role) {
      case PERMISSION.TBR_ADMIN: {
        router.push({ name: 'Point' })
        break
      }
      case PERMISSION.TBR_APPROVER:
      case PERMISSION.MM_APPROVER: {
        router.push({ name: 'Waiting-Approval' })
        break
      }
      default:
        break
    }
  }
}

export const PERMISSION = {
  TBR_ADMIN: 'tbr.admin',
  TBR_APPROVER: 'tbr.approver',
  MM_APPROVER: 'mm.approver',
}
