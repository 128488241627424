<template>
  <div>
    <div v-for="(item, index) in dataRow" v-bind:key="item[groupId]">
      <div
        v-bind:class="
          index === 0 ? 'mx-0   py-5 pt-md-0 pb-md-3' : 'mx-0   py-5 py-md-3'
        "
      >
        <h3 class="title-table">
          ร้าน {{ item[groupName] }} (รหัสร้าน : {{ item[groupId] }})
        </h3>
      </div>
      <v-data-table
        class="mx-0 pa-0"
        :loading="isLoading"
        :headers="headers"
        :items="item.data"
        hide-default-footer
        no-data-text="ไม่พบข้อมูล"
        no-results-text="ไม่พบข้อมูล"
        :items-per-page="perPage"
        :page-count="tablePagesCount"
      >
        <template
          v-for="header in headers"
          v-slot:[`item.${header.value}`]="{ item }"
        >
          <div v-bind:key="header.value">
            <div
              v-if="
                header.dataType === 'number' && header.dataType !== undefined
              "
              class="text-danger"
            >
              {{ $numberFormat(item[header.value]) }}
            </div>
            <div
              v-if="
                header.dataType === 'datetime' && header.dataType !== undefined
              "
              class="text-danger"
            >
              {{ moment(item[header.value]).format("DD/MM/YYYY HH:mm") }}
            </div>
            <div
              v-if="
                header.dataType === 'status' && header.dataType !== undefined
              "
              class="text-danger"
            >
              <MyStatus
                :status="item[header.value]"
                :variant="
                  item[header.value] === 'Waiting TBR Approve' ||
                  item[header.value] === 'Waiting MM Approve' ||
                  item[header.value] === 'Wating for TRR' ||
                  item[header.value] === 'Approved'
                    ? 'accept'
                    : 'reject'
                "
              />
            </div>
            <div v-if="header.dataType === undefined" v-bind:key="header.value">
              {{ item[header.value] }}
            </div>
          </div>
        </template>
        <!-- <template v-slot:item.status="{ item }">
          <div class="text-status">
            {{ item.status }}
          </div>
        </template> -->
      </v-data-table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import MyStatus from "@/components/MyStatus";
export default {
  name: "Table-Group",
  components: { MyStatus },
  props: {
    headers: {
      type: Array,
    },
    dataRow: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
    groupId: {
      type: String,
    },
    groupName: {
      type: String,
    },
    perPage: {
      type: Number,
      default: 50,
    },
    pageCount: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    tablePagesCount: {
      get() {
        return this.pageCount;
      },
      set(value) {
        this.pageCount = value;
      },
    },
  },
  methods: {
    moment,
  },
};
</script>
<style lang="scss">
.title-table {
  color: #000000;
}
</style>
