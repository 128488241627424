<template>
  <div id="auth-layout">
    <div v-if="hamburger">
      <v-navigation-drawer temporary absolute v-model="drawer">
        <v-img
          class="mt-6 ma-auto"
          :src="require('@/assets/logo.svg')"
          contain
          width="93"
          height="37"
        />
        <div class="profile-padding">
          <ProfileInfo :profileData="profileData" />
        </div>
        <h4 class="nav-padding">Menu</h4>
        <v-list dense nav class="nav-padding">
          <div v-for="(i, index) in generateMenu" :key="index">
            <v-list-item
              color="primary"
              :id="i.id"
              v-show="!i.subGroup"
              :key="`main-${index}`"
              link
              :to="i.pathName"
            >
              <v-list-item-action v-if="i.iconName">
                <v-icon>{{ i.iconName }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ i.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-show="i.subGroup"
              :key="`submain-${index}`"
              :prepend-icon="i.iconName"
              v-model="i.active"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="i.name"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                :id="subItem.id"
                v-for="subItem in i.subItems"
                :key="subItem.name"
                link
                :to="subItem.pathName"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="subItem.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>
        <template v-slot:append>
          <div class="signout-padding">
            <v-btn block color="secondary" depressed @click="signOut()">
              Sign out
              <v-spacer />
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </div>
    <div v-else>
      <v-navigation-drawer permanent app>
        <v-img
          class="mt-6 ma-auto"
          :src="require('@/assets/logo.svg')"
          contain
          width="93"
          height="37"
        />
        <div class="profile-padding">
          <ProfileInfo :profileData="profileData" />
        </div>
        <h4 class="nav-padding">Menu</h4>
        <v-list dense nav class="nav-padding">
          <div v-for="(i, index) in generateMenu" :key="index">
            <v-list-item
              color="primary"
              :id="i.id"
              v-show="!i.subGroup"
              :key="`main-${index}`"
              link
              :to="i.pathName"
            >
              <v-list-item-action v-if="i.iconName">
                <v-icon>{{ i.iconName }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ i.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-show="i.subGroup"
              :key="`submain-${index}`"
              :prepend-icon="i.iconName"
              v-model="i.active"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="i.name"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                :id="subItem.id"
                v-for="subItem in i.subItems"
                :key="subItem.name"
                link
                :to="subItem.pathName"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="subItem.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>
        <template v-slot:append>
          <div class="signout-padding">
            <v-btn block color="secondary" depressed @click="signOut()">
              Sign out
              <v-spacer />
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </div>
    <v-app-bar v-if="hamburger" app color="#F2F2F2" flat height="64">
      <v-toolbar-title class="d-flex align-center">
        <v-btn
          icon
          v-if="hamburger"
          @click.stop="drawer = !drawer"
          class="mr-2"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-toolbar-title>
      <v-spacer />
      <!-- <v-img
        class="home-logo"
        :src="require('@/assets/logo.svg')"
        height="36px"
        contain
      /> -->
      <v-spacer />
      <v-spacer />
    </v-app-bar>
    <slot />
    <Alert
      :snackbar="snackbar"
      :type="alertType"
      :text="alertText"
      @close="onCloseAlert"
    />
    <div v-show="isLoading" class="loading-process">
      <v-progress-circular indeterminate color="white" />
    </div>
  </div>
</template>

<script>
import { isEmpty } from "lodash";

import { getToken, getJWTToken, PERMISSION } from "@/plugins/auth";

import Alert from "@/components/Alert.vue";
import ProfileInfo from "@/components/ProfileInfo.vue";

import Utils from "@/mixin";

export default {
  mixins: [Utils],
  components: {
    Alert,
    ProfileInfo,
  },
  created() {
    this.jwtToken = getJWTToken();
    window.addEventListener("resize", this.checkHamburger);
    this.flag = true;
  },
  destroyed() {
    window.removeEventListener("resize", this.checkHamburger);
  },
  computed: {
    profileData() {
      const { jwtToken } = this;
      return {
        // image: require('@/assets/profile-icon.png'),
        name: jwtToken?.name || jwtToken?.email,
      };
    },
    generateMenu() {
      const { jwtToken } = this;
      let menu = [];
      if (jwtToken?.role) {
        const { role } = jwtToken;
        switch (role) {
          case PERMISSION.TBR_ADMIN: {
            menu = [...this.adminMenu];
            break;
          }
          case PERMISSION.TBR_APPROVER:
          case PERMISSION.MM_APPROVER: {
            menu = [...this.approverMenu];
            break;
          }
          default:
            break;
        }
      }
      return menu;
    },
    snackbar() {
      return !isEmpty(this.alert?.message) ? true : false;
    },
    alertText() {
      return !isEmpty(this.alert) ? this.alert?.message : "";
    },
    alertType() {
      return !isEmpty(this.alert) ? this.alert?.type : "";
    },
  },
  data: () => ({
    adminMenu: [
      {
        id: "upload-point",
        pathName: "/upload-point",
        name: "Upload Point",
      },
      {
        id: "waiting-approval",
        pathName: "/waiting-approval",
        name: "Waiting for Approval",
      },
      {
        id: "rejected-transaction",
        pathName: "/rejected-transaction",
        name: "Rejected Transaction",
      },
      {
        id: "approved-transaction",
        pathName: "/approved-transaction",
        name: "Approved Transaction",
      },
      {
        id: "manage-target",
        pathName: "/manage-target",
        name: "จัดการ Target",
      },
      {
        id: "manage-ratio",
        pathName: "/manage-ratio",
        name: "จัดการ Ratio พิเศษ",
      },
      {
        id: "summary-review",
        pathName: "/summary-review",
        name: "Review Summary",
      },
      {
        id: "submitting-scores",
        pathName: "/submitting-scores",
        name: "ตั้งค่าการส่งคะแนน",
      },
      // {
      //   id: 'sku-settings',
      //   pathName: '/sku-settings',
      //   name: 'SKU Settings',
      // },
    ],
    approverMenu: [
      {
        id: "waiting-approval",
        pathName: "/waiting-approval",
        name: "Waiting for Approval",
      },
      {
        id: "rejected-transaction",
        pathName: "/rejected-transaction",
        name: "Rejected Transaction",
      },
      {
        id: "approved-transaction",
        pathName: "/approved-transaction",
        name: "Approved Transaction",
      },
    ],
    hamburger: false,
    drawer: false,
    flag: false,
    jwtToken: null,
  }),
  async mounted() {
    const token = this.auth.token || getToken();
    if (isEmpty(token)) {
      this.$router.push({ name: "Login" });
    }
    console.log(token);
    // this.jwtToken = {
    //   role: PERMISSION.TBR_ADMIN
    // }
    this.checkHamburger();
  },
  methods: {
    checkHamburger(e) {
      const md = 992;
      const innerWidth = e?.target?.innerWidth || window.innerWidth;
      this.hamburger = innerWidth <= md;
      // console.log(innerWidth, this.hamburger);
      if (this.flag || innerWidth > md) {
        this.drawer = false;
        this.flag = false;
      }
    },
    onCloseAlert() {
      this.alert.message = "";
    },
    signOut() {
      this.logout();
    },
  },
};
</script>

<style lang="scss">
$bg-gray: #f2f2f2;
$gray: #d9d9d9;

.branch-button {
  border-radius: 4px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  letter-spacing: 0 !important;
  font-size: 0.8125rem !important;
  padding: 2px 8px !important;
  span {
    justify-content: flex-start;
  }
}

#auth-layout {
  min-height: 100vh;
  position: relative;
  .v-navigation-drawer {
    background: $bg-gray;
    position: fixed;
  }
  .nav-content {
    height: 90px !important;
  }
  header,
  .nav-content {
    border-width: 0 0 thin;
    border-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12) !important;
  }
  .nav-padding {
    padding: 8px 30px;
  }
  .profile-padding {
    padding: 15px 30px 20px 30px;
  }
  .signout-padding {
    padding: 8px 30px 30px;
    .v-btn {
      color: #000000;
    }
  }
  .v-list {
    .v-list-item--active {
      &::before {
        opacity: 1;
      }
      .v-list-item__content {
        color: #fff !important;
        z-index: 1;
      }
    }
    .v-list-item {
      background: $gray;
    }
  }
  .loading-process {
    z-index: 999;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.3);
    > div {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
