import { get, isEmpty } from 'lodash'

import { setAuthorization, REQUEST_POST } from './API_Request'
import { getToken, redirectAuth } from '@/plugins/auth'
import { AlertMessage, errorMessage } from '@/plugins/common'
import { GLOBAL_MESSAGE, LOGIN_MESSAGE } from '@/plugins/message'

export const state = {
  token: '',
}

export const mutations = {
  async LOGIN(state, data) {
    data.toggleLoading()
    try {
      const headers = {
        Authorization: state.apptoken && `Bearer ${state.apptoken}`,
      }
      const form = {
        channel: 'password',
        credential: data.formData,
      }

      const res = await REQUEST_POST('/api/auth', form, { headers })
      const token = {
        accessToken: get(res, 'data.access_token'),
        tokenType: get(res, 'data.token_type'),
        expiresIn: get(res, 'data.expires_in'),
      }

      state.token = token.accessToken
      localStorage.setItem('token', token.accessToken)
      data.toggleLoading()

      if (get(res, 'status') === 200) {
        const alert = new AlertMessage(LOGIN_MESSAGE.SUCCESS, 'ok', 'success')
        data.toggleAlert(alert)
        redirectAuth()
        setAuthorization(token.accessToken)
        return state
      }
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, 'error', 'error')
      if (error) alert = new AlertMessage(LOGIN_MESSAGE.ERROR, 'error', 'error')
      data.toggleAlert(alert)
      return e
    }
  },
  LOGOUT() {
    localStorage.removeItem('token');
     //location.reload()
     location.replace("/login");
  },
}

export const actions = {
  login(context, formData) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    context.commit('LOGIN', { toggleLoading, formData, toggleAlert })
  },
  logout(context) {
    context.commit('LOGOUT')
  },
}

export const getters = {
  // Add Logic Before Computed
  auth(state) {
    const token = getToken()
    if (!isEmpty(token)) state.token = token
    return state
  },
}

export const authentication = {
  state,
  mutations,
  actions,
  getters,
}
