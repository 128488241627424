import { REQUEST_GET, REQUEST_POST, REQUEST_PUT } from "./API_Request";
import { AlertMessage, errorMessage } from "@/plugins/common";
import { GLOBAL_MESSAGE } from "@/plugins/message";
export const state = {
  summaryReview: {
    data: [],
    total: 0,
    perPage: 50,
    pageCount: 0,
    error: false,
    errorMessage: "",
  },
  summaryReviewDetail: {
    data: [],
    total: 0,
    perPage: 50,
    pageCount: 1,
  },
  summaryReviewPointDetail: {
    data: [],
    total: 0,
    perPage: 50,
    pageCount: 1,
    error: "",
  },
  waitingSummaries: {
    data: [],
    pointUploadId: null,
  },
  rejectedSummaries: {
    data: [],
    total: 0,
    fileName: "",
    remark: "",
    dateReject: "",
    status: "",
    perPage: 50,
    pageCount: 1,
    dataDetail: [],
  },
  approvedSummaries: {
    data: [],
  },
};
export const mutations = {
  async GET_SUMMARY_REVIEW_LIST(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET(
        "/api/point-transactions/waitsummary-history",
        data.form
      );

      if (res?.data?.data && res?.data?.data.length > 0) {
        state.summaryReview.data = res?.data?.data;
        state.summaryReview.total = res?.data?.total || 0;
        state.summaryReview.perPage = res?.data?.perPage || 50;
        state.summaryReview.pageCount = res?.data?.lastPage || 0;
      } else {
        state.summaryReview = {
          ...state.summaryReview,
          data: [],
          total: 0,
          perPage: 50,
          pageCount: 1,
        };
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_SUMMARY_REVIEW_DETAIL_LIST(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET(
        "/api/point-transactions/wait-summary?pointUploadId=" +
          data.form.pointUploadId,
        data.form
      );

      if (res?.data?.data && res?.data?.data.length > 0) {
        state.summaryReviewDetail.data = res?.data?.data;
        state.summaryReviewDetail.total = res?.data?.total || 0;
        state.summaryReviewDetail.perPage = res?.data?.perPage || 50;
        state.summaryReviewDetail.pageCount = res?.data?.lastPage || 0;
      } else {
        state.summaryReviewDetail = {
          ...state.summaryReviewDetail,
          data: [],
          total: 0,
          perPage: 50,
          pageCount: 1,
        };
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_SUMMARY_REVIEW_DETAIL_POINT_LIST(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET(
        "/api/summarypoints?pointUploadId=" + data.form.pointUploadId
      );

      if (res?.data?.ok) {
        state.summaryReviewPointDetail.data = res?.data?.result;
        state.summaryReviewDetail.total = res?.data?.total || 0;
        state.summaryReviewDetail.perPage = res?.data?.perPage || 50;
        state.summaryReviewPointDetail.pageCount = res?.data?.lastPage || 0;
        state.summaryReviewPointDetail.error = "";
      } else {
        state.summaryReviewPointDetail = {
          ...state.summaryReviewPointDetail,
          data: [],
          total: 0,
          perPage: 50,
          pageCount: 1,
          error: res.data?.error,
        };

        let alert = new AlertMessage(res.data?.error !=="" ?  res.data?.error: GLOBAL_MESSAGE.ERROR, "error", "error");
        data.toggleAlert(alert);
      }
      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      return error;
    }
  },
  async GET_SUMMARY_WAITING_ADMIN_LIST(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET("/api/summarypoints/waiting", data.form);
      if (res.data && res.data.ok && res.data) {
        state.waitingSummaries.data = res?.data?.result;
        if (res?.data?.result.length > 0) {
          state.waitingSummaries.pointUploadId =
            res?.data?.result[0].items[0].pointUploadId;
        } else {
          state.waitingSummaries = {
            ...state.waitingSummaries,
            data: [],
            pointUploadId: null,
          };
        }
      } else {
        state.waitingSummaries = {
          ...state.waitingSummaries,
          data: [],
          pointUploadId: null,
        };
        let alert = new AlertMessage(res.data.error, "error", "error");
        data.toggleAlert(alert);
      }
      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_SUMMARY_WAITING_TRR_LIST(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET(
        "/api/summarypoints/tbr/waiting",
        data.form
      );
      if (res.data && res.data.ok) {
        if (res?.data?.result && res?.data?.result.length > 0) {
          state.waitingSummaries.data = res?.data?.result || [];
          if (
            res?.data?.result[0]?.items &&
            res?.data?.result[0]?.items.length > 0
          ) {
            state.waitingSummaries.pointUploadId =
              res?.data?.result[0].items[0].pointUploadId;
          } else {
            state.waitingSummaries.pointUploadId = null;
          }
        } else {
          state.waitingSummaries = {
            ...state.waitingSummaries,
            data: [],
            pointUploadId: null,
          };
        }
      } else {
        state.waitingSummaries = {
          ...state.waitingSummaries,
          data: [],
          pointUploadId: null,
        };
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_SUMMARY_WAITING_MM_LIST(state, data) {
    data.toggleLoading();
    try {
      state.waitingSummaries = {
        ...state.waitingSummaries,
        data: [],
        pointUploadId: null,
      };
      const res = await REQUEST_GET("/api/summarypoints/mm/waiting", data.form);

      if (res.data && res.data.ok) {
        if (res?.data?.result && res?.data?.result.length > 0) {
          state.waitingSummaries.data = res?.data?.result || [];
          if (
            res?.data?.result[0]?.items &&
            res?.data?.result[0]?.items.length > 0
          ) {
            state.waitingSummaries.pointUploadId =
              res?.data?.result[0].items[0].pointUploadId;
          } else {
            state.waitingSummaries.pointUploadId = null;
          }
        } else {
          state.waitingSummaries = {
            ...state.waitingSummaries,
            data: [],
            pointUploadId: null,
          };
        }
      } else {
        state.waitingSummaries = {
          ...state.waitingSummaries,
          data: [],
          pointUploadId: null,
        };
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_SUMMARY_WAITING_MM_DONE_LIST(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET("/api/summarypoints/approved", data.form);

      if (res.data && res.data.ok) {
        if (res?.data?.result && res?.data?.result.length > 0) {
          state.waitingSummaries.data = res?.data?.result || [];
          if (
            res?.data?.result[0]?.items &&
            res?.data?.result[0]?.items.length > 0
          ) {
            state.waitingSummaries.pointUploadId =
              res?.data?.result[0].items[0].pointUploadId;
          } else {
            state.waitingSummaries.pointUploadId = null;
          }
        } else {
          state.waitingSummaries = {
            ...state.waitingSummaries,
            data: [],
            pointUploadId: null,
          };
        }
      } else {
        state.waitingSummaries = {
          ...state.waitingSummaries,
          data: [],
          pointUploadId: null,
        };
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_REJECTED_SUMMARY_LIST(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET(
        "/api/summarypoints/reject-history",
        data.form
      );

      if (res?.data?.data != undefined && res?.data?.data.length > 0) {
        state.rejectedSummaries.data = res?.data?.data || [];
        state.rejectedSummaries.total = res?.data?.total || 0;
        state.rejectedSummaries.perPage = res?.data?.perPage || 50;
        state.rejectedSummaries.pageCount = res?.data?.lastPage || 1;
      } else {
        state.rejectedSummaries = {
          ...state.rejectedSummaries,
          data: [],
          total: 0,
          fileName: "",
          remark: "",
          dateReject: "",
          status: "",
          perPage: 50,
          pageCount: 1,
        };
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_REJECTED_SUMMARY_DETAIL(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET("/api/summarypoints/rejected", data.form);
      state.rejectedSummaries = {
        ...state.rejectedSummaries,
        dataDetail: [],
        fileName: "",
        remark: "",
        dateReject: "",
        status: "",
      };
      if (res?.data && res?.data?.ok) {
        if (res?.data.result && res?.data.result.length > 0) {
          for (let index = 0; index < res?.data?.result.length; index++) {
            state.rejectedSummaries.dataDetail.push(res?.data?.result[index]);
          }

          (state.rejectedSummaries.fileName = res?.data?.result[0].fileName),
            (state.rejectedSummaries.remark = res?.data?.result[0].remark);
          state.rejectedSummaries.dateReject = res?.data?.result[0].dateReject;
          state.rejectedSummaries.status = res?.data?.result[0].status;
        }
      } else {
        state.rejectedSummaries = {
          ...state.rejectedSummaries,
          dataDetail: [],
          fileName: "",
          remark: "",
          dateReject: "",
          status: "",
        };
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async GET_APPROVED_SUMMARY_LIST(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET("/api/summarypoints/approved", data.form);
      if (res?.data?.ok) {
        if (res?.data?.result && res?.data?.result.length > 0) {
          state.approvedSummaries.data = res?.data?.result || [];
        } else {
          state.approvedSummaries = {
            ...state.approvedSummaries,
            data: [],
          };
        }
      } else {
        state.approvedSummaries = {
          ...state.approvedSummaries,
          data: [],
        };
      }

      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      return error;
    }
  },
  async SAVE_SUMMARY_REVIEW(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_POST("/api/summarypoints", data.form);

      if (res.data?.ok) {
        state.summaryReview.error = false;
        state.summaryReview.errorMessage = "";
        data.toggleLoading();
        const alert = new AlertMessage(
          GLOBAL_MESSAGE.UPLOAD.SUCCESS,
          "ok",
          "success"
        );
        data.toggleAlert(alert);
      } else {
        state.summaryReview.error = true;
        state.summaryReview.errorMessage = res.data?.error;
        data.toggleLoading();
        let alert = new AlertMessage(res.data?.error, "error", "error");
        data.toggleAlert(alert);
      }
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async CLEAR_SUMMARY(state, data) {
    data.toggleLoading();
    try {
      state.summaryReviewDetail = {
        ...state.summaryReviewDetail,
        data: [],
        total: 0,
        perPage: 50,
        pageCount: 1,
      };
      state.summaryReviewPointDetail = {
        ...state.summaryReviewPointDetail,
        data: [],
        total: 0,
        perPage: 50,
        pageCount: 1,
      };
      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      return error;
    }
  },

  async REJECT_TBR_SUMMARY(state, data) {
    data.toggleLoading();
    try {
      let body = {
        pointUploadId: state.waitingSummaries?.pointUploadId,
        remark: data?.form?.remark,
      };
      const res = await REQUEST_PUT("/api/summarypoints/tbr/reject", body);

      if (res.data?.ok) {
        data.toggleLoading();
        const alert = new AlertMessage(
          GLOBAL_MESSAGE.UPLOAD.SUCCESS,
          "ok",
          "success"
        );
        data.toggleAlert(alert);
      } else {
        data.toggleLoading();
        let alert = new AlertMessage(res.data?.error, "error", "error");
        data.toggleAlert(alert);
      }
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(
        GLOBAL_MESSAGE.REJECT.ERROR,
        "error",
        "error"
      );
      data.toggleAlert(alert);
      return error;
    }
  },

  async REJECT_MM_SUMMARY(state, data) {
    data.toggleLoading();
    try {
      let body = {
        pointUploadId: state.waitingSummaries?.pointUploadId,
        remark: data?.form?.remark,
      };
      const res = await REQUEST_PUT(`/api/summarypoints/mm/reject`, body);

      if (res.data?.ok) {
        data.toggleLoading();
        const alert = new AlertMessage(
          GLOBAL_MESSAGE.UPLOAD.SUCCESS,
          "ok",
          "success"
        );
        data.toggleAlert(alert);
      } else {
        data.toggleLoading();
        let alert = new AlertMessage(res.data?.error, "error", "error");
        data.toggleAlert(alert);
      }
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.REJECT.ERROR,
        "error",
        "error"
      );
      data.toggleAlert(alert);
      return error;
    }
  },

  async APPROVE_TBR_SUMMARY(state, data) {
    data.toggleLoading();
    try {
      let body = {
        pointUploadId: state.waitingSummaries?.pointUploadId,
      };
      const res = await REQUEST_PUT(`/api/summarypoints/tbr/approve`, body);

      if (res.data?.ok) {
        data.toggleLoading();
        const alert = new AlertMessage(
          GLOBAL_MESSAGE.UPLOAD.SUCCESS,
          "ok",
          "success"
        );
        data.toggleAlert(alert);
      } else {
        data.toggleLoading();
        let alert = new AlertMessage(res.data?.error, "error", "error");
        data.toggleAlert(alert);
      }
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.APPROVE.ERROR,
        "error",
        "error"
      );
      data.toggleAlert(alert);
      return error;
    }
  },
  async APPROVE_MM_SUMMARY(state, data) {
    data.toggleLoading();
    try {
      let body = {
        pointUploadId: state.waitingSummaries?.pointUploadId,
      };
      const res = await REQUEST_PUT(`/api/summarypoints/mm/approve`, body);

      if (res.data?.ok) {
        data.toggleLoading();
        const alert = new AlertMessage(
          GLOBAL_MESSAGE.UPLOAD.SUCCESS,
          "ok",
          "success"
        );
        data.toggleAlert(alert);
      } else {
        data.toggleLoading();
        let alert = new AlertMessage(res.data?.error, "error", "error");
        data.toggleAlert(alert);
      }
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      const alert = new AlertMessage(
        GLOBAL_MESSAGE.APPROVE.ERROR,
        "error",
        "error"
      );
      data.toggleAlert(alert);
      return error;
    }
  },
};
export const actions = {
  async getSummaryReviewList(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_SUMMARY_REVIEW_LIST(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getSummaryReviewDetailList(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_SUMMARY_REVIEW_DETAIL_LIST(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getSummaryReviewDetailPointList(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_SUMMARY_REVIEW_DETAIL_POINT_LIST(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getAdminSummaries(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_SUMMARY_WAITING_ADMIN_LIST(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getTRPSummaries(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_SUMMARY_WAITING_TRR_LIST(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getMMSummaries(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_SUMMARY_WAITING_MM_LIST(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getMMSummariesDone(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_SUMMARY_WAITING_MM_DONE_LIST(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getRejectedSummariesList(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_REJECTED_SUMMARY_LIST(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getRejectedSummariesDetail(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_REJECTED_SUMMARY_DETAIL(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async getApprovedSummariesList(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_APPROVED_SUMMARY_LIST(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async clearSummary(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_SUMMARY_REVIEW_DETAIL_POINT_LIST(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async saveSummary(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.SAVE_SUMMARY_REVIEW(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async rejectTRPSummary(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.REJECT_TBR_SUMMARY(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async rejectMMSummary(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.REJECT_MM_SUMMARY(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async approveTbrSummary(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.APPROVE_TBR_SUMMARY(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async approveMMSummary(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.APPROVE_MM_SUMMARY(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
};
export const getters = {
  summaryReview(state) {
    return state.summaryReview;
  },
  summaryReviewDetail(state) {
    return state.summaryReviewDetail;
  },
  summaryReviewPointDetail(state) {
    return state.summaryReviewPointDetail;
  },
  waitingSummaries(state) {
    return state.waitingSummaries;
  },
  rejectedSummaries(state) {
    return state.rejectedSummaries;
  },
  approvedSummaries(state) {
    return state.approvedSummaries;
  },
};
export const summaries = {
  state,
  mutations,
  actions,
  getters,
};
