<template>
  <div class="point-container">
    <h2 class="mb-3">Upload Point</h2>
    <Card>
      <v-form ref="form">
        <v-row class="mt-3">
          <v-col class="ml-0 ml-md-4" cols="12" md="6" lg="5" xl="4">
            <v-file-input
              dense
              id="fileUpload"
              prepend-icon=""
              background-color="#fff"
              outlined
              label="ชื่อไฟล์"
              v-model="file"
              :rules="[rules.required]"
            />
            <h4 class="red--text">{{ flieError }}</h4>
          </v-col>
          <v-col class="text-end text-md-start" cols="12" md="5" lg="4" xl="3">
            <v-btn
              class="secondary-btn mr-5"
              width="120"
              height="40"
              color="secondary"
              depressed
              @click="handleUpload"
            >
              <v-icon>mdi-plus</v-icon>Add file
            </v-btn>
            <v-btn
              width="120"
              height="40"
              color="primary"
              depressed
              :disabled="file === null"
              @click="uploadFile"
            >
              Upload
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </Card>
    <div v-if="dataList.length > 0">
      <Card>
        <v-data-table
          class="mx-0 mx-md-4"
          :loading="isLoading"
          :headers="headers"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :items="dataList"
          hide-default-footer
          @page-count="pageCount = $event"
          no-data-text="ไม่พบข้อมูล"
          no-results-text="ไม่พบข้อมูล"
        >
          <template v-slot:item.no="{ item }">
            <div v-if="item.isDelete" class="red--text">{{ item.no }}</div>
            <div v-else>{{ item.no }}</div>
          </template>
          <template v-slot:item.documentCode="{ item }">
            <div v-if="item.isDelete" class="red--text">
              {{ item.documentCode }}
            </div>
            <div v-else>{{ item.documentCode }}</div>
          </template>
          <template v-slot:item.userId="{ item }">
            <div v-if="item.isDelete" class="red--text">
              {{ item.userId }}
            </div>
            <div v-else>{{ item.userId }}</div>
          </template>
          <template v-slot:item.name="{ item }">
            <div v-if="item.isDelete" class="red--text">
              {{ item.name }}
            </div>
            <div v-else>{{ item.name }}</div>
          </template>
          <template v-slot:item.phone="{ item }">
            <div v-if="item.isDelete" class="red--text">
              {{ item.phone }}
            </div>
            <div v-else>{{ item.phone }}</div>
          </template>
          <template v-slot:item.sku="{ item }">
            <div v-if="item.isDelete" class="red--text">
              {{ item.sku }}
            </div>
            <div v-else>{{ item.sku }}</div>
          </template>

          <template v-slot:item.qty="{ item }">
            <div v-if="item.isDelete" class="red--text">
              {{ $numberFormat(item.qty) }}
            </div>
            <div v-else>{{ $numberFormat(item.qty) }}</div>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn v-if="!item.isDelete" icon @click="deleteItem(item)">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div class="mt-4 mt-md-6">
          <v-row dense class="ml-0 ml-md-4">
            <v-row dense>
              <v-col cols="12" md="3" class="align-self-center">
                Total Records: {{ points.total || 0 }}
              </v-col>
              <v-col
                cols="6"
                md="2"
                class="align-self-center text-no-wrap align-center d-flex"
              >
                <div class="mr-2">Transactions per page:</div>
                <v-select
                  disabled
                  dense
                  outlined
                  hide-details
                  :value="itemsPerPage"
                  @change="itemsPerPage = parseInt($event, 10)"
                  :items="itemsPerPageOptions"
                />
              </v-col>
              <v-spacer />
            </v-row>
            <v-spacer />
            <v-col
              v-if="!previewFail"
              class="text-right mr-0 mr-md-4 d-md-block d-none"
              cols="12"
              md="auto"
            >
              <v-btn
                class="secondary-btn mr-5"
                width="120"
                height="40"
                color="secondary"
                depressed
                @click="cancelItem"
              >
                Cancel
              </v-btn>
              <v-btn
                width="120"
                height="40"
                color="primary"
                depressed
                @click="saveItem"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="pageCount > 0"
              cols="12"
              class="pt-auto pt-md-0 ml-0 ml-md-2 align-self-center"
            >
              <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="7"
                color="primary"
              />
            </v-col>
            <v-col
              v-if="!previewFail"
              cols="12"
              class="d-md-none d-block text-right"
            >
              <v-btn
                class="secondary-btn mr-5"
                width="120"
                height="40"
                color="secondary"
                depressed
              >
                Cancel
              </v-btn>
              <v-btn width="120" height="40" color="primary" depressed>
                Save
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </Card>
    </div>
    <ConfirmModal v-model="conf.status" :data="conf" @confirm="deleteData" />
    <ConfirmModal
      v-model="cancelConf.status"
      :data="cancelConf"
      @confirm="clearData"
    />
    <ConfirmModal
      v-model="saveConf.status"
      :data="saveConf"
      @confirm="saveData"
    />
  </div>
</template>

<script>
import { reduce } from "lodash";
import Card from "@/components/Card";
import ConfirmModal from "@/components/ConfirmModal";

import { itemsPerPageOptions } from "@/plugins/common";

import Utils from "@/mixin";

export default {
  mixins: [Utils],
  name: "Point",
  components: {
    Card,
    ConfirmModal,
  },
  computed: {
    dataList() {
      const { points } = this;
      const result = reduce(
        points.data,
        (a, b) => {
          a.push({
            ...b,
          });
          return a;
        },
        []
      );
      return result;
    },
  },
  data: () => ({
    file: null,
    flieError: "",
    previewFail: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 50,
    itemsPerPageOptions: [],
    conf: {
      status: false,
    },
    cancelConf: {
      title: "ยืนยันการเคลียร์ข้อมูล",
      status: false,
    },
    saveConf: {
      title: "ยืนยันการบันทึกข้อมูล",
      status: false,
    },
    headers: [
      { text: "ลำดับ", value: "no", sortable: false },
      { text: "เลขที่เอกสาร", value: "documentCode" },
      { text: "รหัสร้านค้า", value: "userId" },
      { text: "ชื่อร้านค้า", value: "name" },
      { text: "เบอร์โทรศัพท์", value: "phone" },
      { text: "SKU", value: "sku" },

      { text: "จำนวน SKU", value: "qty", sortable: false },

      { text: "Action", value: "action", sortable: false, align: "center" },
    ],
    rules: {
      required: (value) => !!value || "*กรุณาเลือกไฟล์",
    },
  }),
  methods: {
    async getInitialData() {
      await this.clearPoints();
    },
    deleteItem(data) {
      this.conf = {
        ...this.conf,
        status: true,
        data,
      };
    },
    async deleteData(value = false) {
      const { conf } = this;

      if (value) {
        await this.deletePoint(conf?.data?.no);
      }
      this.conf = {
        ...conf,
        status: false,
        data: undefined,
      };
    },
    handleUpload() {
      document.getElementById("fileUpload").click();
    },

    async uploadFile() {
      const { file } = this;
      const formData = { file };
      this.flieError = "";
      if (formData) {
        if (formData.file) {
          const pattern = /\xlsx$/;

          const extension = formData.file.name.split(".");
          let ext = extension[extension.length - 1].toLowerCase();

          if (!pattern.test(ext)) {
            this.flieError = "*รองรับไฟล์นามสกุลไฟล์ .xlsx เท่านั้น";

            return;
          }
          await this.uploadPoints(formData);
          const { points } = this;
          if (points.error !== "") {
            this.previewFail = true;
            this.flieError = points.error;
          } else {
            this.flieError = "";
            this.previewFail = false;
          }
        }
      } else {
        this.flieError = "*กรุณาเลือกไฟล์";
        return;
      }
    },
    cancelItem() {
      this.cancelConf = {
        ...this.cancelConf,
        status: true,
      };
    },
    async clearData(value = false) {
      if (value) {
        this.$refs.form && this.$refs.form.reset();
        await this.clearPoints();
        this.previewFail = false;
        this.flieError = "";
        this.page = 1;
      }
      this.cancelConf = {
        ...this.cancelConf,
        status: false,
        data: undefined,
      };
    },
    saveItem() {
      this.saveConf = {
        ...this.saveConf,
        status: true,
      };
    },
    async saveData(value = false) {
      if (value) {
        this.$refs.form && this.$refs.form.reset();
        await this.savePoints();

        const { points } = this;
        if (points.error !== "") {
          // this.previewFail = true;
          this.flieError = points.error;
        } else {
          this.flieError = "";
          this.page = 1;
          this.previewFail = false;
        }
      }
      this.saveConf = {
        ...this.saveConf,
        status: false,
        data: undefined,
      };
    },
  },
  mounted() {
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
};
</script>

<style lang="scss">
$gray: #d9d9d9;

.point-container {
  padding: 40px 25px;
  .secondary-btn {
    color: #000 !important;
  }
  .v-pagination {
    @media screen and (max-width: 600px) {
      justify-content: center;
    }
    justify-content: flex-start;
  }
}
</style>
