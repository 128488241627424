var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.dataRow),function(item,index){return _c('div',{key:item[_vm.groupId]},[_c('div',{class:index === 0 ? 'mx-0   py-5 pt-md-0 pb-md-3' : 'mx-0   py-5 py-md-3'},[_c('h3',{staticClass:"title-table"},[_vm._v(" ร้าน "+_vm._s(item[_vm.groupName])+" (รหัสร้าน : "+_vm._s(item[_vm.groupId])+") ")])]),_c('v-data-table',{staticClass:"mx-0 pa-0",attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":item.data,"hide-default-footer":"","no-data-text":"ไม่พบข้อมูล","no-results-text":"ไม่พบข้อมูล","items-per-page":_vm.perPage,"page-count":_vm.tablePagesCount},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:`item.${header.value}`,fn:function({ item }){return [_c('div',{key:header.value},[(
              header.dataType === 'number' && header.dataType !== undefined
            )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$numberFormat(item[header.value]))+" ")]):_vm._e(),(
              header.dataType === 'datetime' && header.dataType !== undefined
            )?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.moment(item[header.value]).format("DD/MM/YYYY HH:mm"))+" ")]):_vm._e(),(
              header.dataType === 'status' && header.dataType !== undefined
            )?_c('div',{staticClass:"text-danger"},[_c('MyStatus',{attrs:{"status":item[header.value],"variant":item[header.value] === 'Waiting TBR Approve' ||
                item[header.value] === 'Waiting MM Approve' ||
                item[header.value] === 'Wating for TRR' ||
                item[header.value] === 'Approved'
                  ? 'accept'
                  : 'reject'}})],1):_vm._e(),(header.dataType === undefined)?_c('div',{key:header.value},[_vm._v(" "+_vm._s(item[header.value])+" ")]):_vm._e()])]}}})],null,true)})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }