import Vue from 'vue'
import Vuex from 'vuex'

import { authentication } from './Auth'
import { points } from './Point'
import { transactions } from './Transaction'
import { targets } from './Target'
import { materialMasters } from './MaterialMasters'
import { materialRatios } from './MaterialRatios'
import { summaries } from './Summaries'
import {  submittingScores } from './ SubmittingScores'

Vue.use(Vuex)

export const state = {
  isLoading: false,
  alertMessage: undefined,
}

export const mutations = {
  TOGGLE_LOADING(state) {
    state.isLoading = !state.isLoading
    return state
  },
  TOGGLE_ALERT_MESSAGE(state, alertForm) {
    state.alertMessage = alertForm
    return state
  },
}

export const actions = {
  toggleLoading(context) {
    context.commit('TOGGLE_LOADING')
  },
  toggleAlertMessage(context, formData) {
    context.commit('TOGGLE_ALERT_MESSAGE', formData)
  },
}

export const getters = {
  isLoading(state) {
    return state.isLoading
  },
  alert(state) {
    return state.alertMessage
  },
}

export const modules = {
  authentication,
  points,
  transactions,
  targets,
  materialMasters,
  materialRatios,
  summaries,
  submittingScores
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules,
})
