<template>
  <v-dialog v-model="value" persistent max-width="330">
    <v-card class="pa-2">
      <v-card-title
        :class="`headline justify-center${enableTextArea ? '' : ' pa-12'}`"
      >
        <div :class="`${enableTextArea ? 'pt-6' : ''}`">
          {{ data.title || 'ยืนยันการลบข้อมูล?' }}
        </div>
        <v-form v-model="valid" ref="form" class="mt-4" v-if="enableTextArea">
          <v-textarea
            outlined
            no-resize
            v-model="remark"
            :rules="[rules.maxLength]"
            :placeholder="data.remark || 'กรุณาระบุหมายเหตุ'"
          />
        </v-form>
      </v-card-title>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
              block
              depressed
              v-if="!disabledCancel"
              class="cancel-btn"
              color="secondary"
              @click="
                () => {
                  $emit('confirm', false), $refs.form && $refs.form.reset()
                }
              "
            >
              {{ data.cancelText || 'ยกเลิก' }}
            </v-btn>
          </v-col>
          {{ data.value }}
          <v-col>
            <v-btn
              block
              depressed
              color="primary"
              @click="
                () => {
                  enableTextArea
                    ? $emit('confirm', { modal: true, remark })
                    : $emit('confirm', true),
                    $refs.form && $refs.form.reset()
                }
              "
              :disabled="enableTextArea && !valid ? true : false"
            >
              {{ data.confirmText || 'ยืนยัน' }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    remark: '',
    rules: {
      maxLength: (value) => {
        if (value !== '' && value?.length <= 200) return true
        return 'หมายเหตุต้องความยาวไม่เกิน 200 ตัวอักษร'
      },
    },
  }),
  props: {
    value: {
      type: Boolean,
    },
    data: {
      type: Object,
    },
    disabledCancel: {
      type: Boolean,
      default: false,
    },
    enableTextArea: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.cancel-btn {
  color: #000 !important;
}
.v-form {
  width: 100%;
}
</style>
