<template>
  <div class="d-flex flex-column flex-md-row">
    <div v-for="itemsubMenu in subMenu" :key="`sub-${itemsubMenu.id}`">
      <div v-bind:class="itemsubMenu.id === 1 ? 'col w-100 pl-0' : 'col w-100'">
        <v-btn
          height="40"
          v-bind:color="subTap === itemsubMenu.id ? 'primary' : 'secondary'"
          depressed
          @click="onClickChangeSubTap(itemsubMenu.id)"
        >
          {{ itemsubMenu.title }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    subTap: {
      type: Number,
      default: 1,
    },
    onClickChangeSubTap: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    subMenu: [
      { id: 1, title: "To Do" },
      { id: 2, title: "Done" },
    ],
  }),
};
</script>
