<template>
  <div class="reject-container">
    <h2 class="mb-3">Approved Transaction</h2>
    <TabMenu
      :tap="tap"
      v-bind:onClick="onClickChangeTap"
      :onSearchKey="onSearchKey"
    />
    <div v-if="tap === 'transaction'">
      <TransactionList
        page="approved"
        :currentPage="page"
        :perPage="perPage"
        :pageCount="pageCount"
        :headers="headersTransaction"
        :dataList="dataList"
        :total="total"
        :onChangePage="onChangePage"
      />
    </div>
    <div v-else-if="tap === 'summaryPoint'">
      <SummaryPointList
        page="approved"
        :headers="headersSummary"
        :dataList="dataList"
        :isDisplayStatus="true"
      />
    </div>
    <div v-if="tap === 'target'">
      <TargetList
        page="approved"
        :currentPage="page"
        :perPage="perPage"
        :pageCount="pageCount"
        :headers="headersTarget"
        :dataList="dataList"
        :total="total"
        :onChangePage="onChangePage"
      />
    </div>
  </div>
</template>
<script>
import TabMenu from "@/components/TabMenu";
import TargetList from "@/components/TargetList";
import TransactionList from "@/components/TransactionList";
import SummaryPointList from "@/components/SummaryPointList";
import { reduce } from "lodash";
import Utils from "@/mixin";
export default {
  mixins: [Utils],
  name: "Rejected-Transaction",
  components: {
    TabMenu,
    TargetList,
    TransactionList,
    SummaryPointList,
  },
  data: () => ({
    tap: "transaction",
    currentStore: 1,
    page: 1,
    keyword: "",
    headersTarget: [
      { text: "No", value: "no", sortable: false, dataType: "number" },
      { text: "เบอร์โทร", value: "phone", sortable: false },
      { text: "SKU", value: "sku", sortable: false },
      { text: "Target Month", value: "targetMonth", sortable: false },
      { text: "Target Year", value: "targetYear", sortable: false },
      {
        text: "Target Amount",
        value: "targetAmount",
        sortable: false,
        dataType: "number",
      },
      {
        text: "Date Create",
        value: "dateCreate",
        sortable: false,
      },
      {
        text: "Date TBR Approved",
        value: "dateApprove1",
        sortable: false,
      },
      {
        text: "Date MM Approved",
        value: "dateApprove2",
        sortable: false,
      },
    ],
    headersTransaction: [
      { text: "No", value: "no", sortable: false, dataType: "number" },
      { text: "เลขที่เอกสาร", value: "documentNo", sortable: false },
      { text: "เบอร์โทร", value: "phone", sortable: false },
      { text: "SKU", value: "sku", sortable: false },
      {
        text: "จำนวน SKU",
        value: "qty",
        sortable: false,
        dataType: "number",
      },
      {
        text: "Date Create",
        value: "createdAt",
        sortable: false,
      },
      {
        text: "Date TBR Approved",
        value: "dateApprove1",
        sortable: false,
      },
      {
        text: "Date MM Approved",
        value: "dateApprove2",
        sortable: false,
      },
    ],
    headersSummary: [
      { text: "ลำดับ", value: "no", sortable: false, class: "col-1" },
      { text: "เบอร์โทรศัพท์", value: "phone", class: "col-2 text-center" },
      { text: "SKU", value: "sku", class: "col-2 text-center" },
      {
        text: "จำนวน SKU",
        value: "qty",
        sortable: false,
        class: "col-2 text-right",
        dataType: "number",
      },
      {
        text: "คะแนนที่ได้",
        value: "point",
        sortable: false,
        class: "col-2 text-right",
        dataType: "number",
      },
      { text: "Date Create", value: "createdAt", class: "col-3 text-center" },
    ],
  }),
  computed: {
    dataList() {
      let result = [];
      if (this.tap === "transaction") {
        const { approvedTransactions } = this;
        result = reduce(
          approvedTransactions.data,
          (a, b) => {
            a.push({
              ...b,
            });
            return a;
          },
          []
        );
      } else if (this.tap === "summaryPoint") {
        const { approvedSummaries } = this;
        result = reduce(
          approvedSummaries.data,
          (a, b) => {
            b.items.map((a, index) => {
              a.no = index + 1;
            });
            a.push({
              ...b,
            });
            return a;
          },
          []
        );
      } else if (this.tap === "target") {
        const { approvedTargets } = this;
        result = reduce(
          approvedTargets.data,
          (a, b) => {
            a.push({
              ...b,
            });
            return a;
          },
          []
        );
      }

      return result;
    },

    total() {
      let total = 0;
      if (this.tap === "transaction") {
        const { approvedTransactions } = this;
        total = approvedTransactions.total;
      } else if (this.tap === "target") {
        const { approvedTargets } = this;
        total = approvedTargets.total;
      }

      return total;
    },

    perPage() {
      let perPage = 50;
      if (this.tap === "transaction") {
        const { approvedTransactions } = this;
        perPage = approvedTransactions.perPage;
      } else if (this.tap === "target") {
        const { approvedTargets } = this;
        perPage = approvedTargets.perPage;
      }
      return perPage;
    },

    pageCount() {
      let pageCount = 1;
      if (this.tap === "transaction") {
        const { approvedTransactions } = this;
        pageCount = approvedTransactions.pageCount;
      } else if (this.tap === "target") {
        const { approvedTargets } = this;
        pageCount = approvedTargets.pageCount;
      }
      return pageCount;
    },
  },
  methods: {
    onClickChangeTap(value) {
      this.tap = value;
      this.page = 1;
      this.keyword = "";
      this.getInitialData();
    },
    async getInitialData() {
      let condition = {};
      condition.query = this.keyword ? this.keyword : "";
      if (this.tap !== "summaryPoint") {
        condition.limit = this.perPage ? this.perPage : 50;
        condition.page = this.page ? this.page : 1;
      }

      if (this.tap === "transaction") {
        const fetchApprovedTransactionsData = () =>
          this.getApprovedTransactions(condition);
        await fetchApprovedTransactionsData();
      } else if (this.tap === "summaryPoint") {
        const fetchApprovedSummaryPointData = () =>
          this.getApprovedSummariesList(condition);
        await fetchApprovedSummaryPointData();
      } else if (this.tap === "target") {
        const fetchApprovedTargetData = () =>
          this.getApprovedTargets(condition);
        await fetchApprovedTargetData();
      }
    },
    onSearchKey(value) {
      if (value) {
        let isPass = /^[\u0E00-\u0E7FA-Za-z0-9 -]+$/.test(value.trim());
        if (isPass) {
          this.keyword = value.trim();
        } else {
          this.errorSearch = "ไม่สามารถค้นหาได้ เนื่องจากระบุข้อความไม่ถูกต้อง";
          return;
        }
      } else {
        this.keyword = "";
      }
      this.currentStore = 1;
      this.page = 1;
      this.getInitialData();
    },
    onChangePage(value) {
      this.page = value;
      this.getInitialData();
    },
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0 });
    this.getInitialData();
  },
};
</script>
<style lang="scss">
$gray: #d9d9d9;

.approved-container {
  padding: 40px 25px;
  .filter-btn {
    border: 1px solid $gray;
    &.active {
      // color: $green-text;
      background: $gray;
    }
  }
  .v-pagination {
    @media screen and (max-width: 600px) {
      justify-content: center;
    }
    justify-content: flex-start;
  }
}
</style>
