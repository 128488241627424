<template>
  <div class="reject-container">
    <h2 class="mb-3">Rejected Transaction</h2>
    <TabMenu
      :tap="tap"
      :isSearch="isSearch"
      v-bind:onClick="onClickChangeTap"
      :onSearchKey="onSearchKey"
    />
    <div v-if="!isRejectDetail">
      <RejectList
        :currentPage="page"
        :perPage="perPage"
        :pageCount="pageCount"
        :total="total"
        :dataList="dataList"
        :onChangePage="onChangePage"
        :onViewDetail="onViewDetail"
      />
    </div>
    <div v-else>
      <div v-if="tap === 'transaction'">
        <TransactionList
          page="reject"
          :currentPage="page"
          :perPage="perPage"
          :pageCount="pageCount"
          :headers="headersTransaction"
          :transactionTitle="title"
          :dataList="dataList"
          :total="total"
          :onChangePage="onChangePage"
          :onClickBack="onClickBack"
        />
      </div>
      <div v-else-if="tap === 'summaryPoint'">
        <SummaryPointList
          page="reject"
          :currentStore="currentStore"
          :headers="headersSummary"
          :summaryTitle="title"
          :dataList="dataDetailList"
          :onClickBack="onClickBack"
          :isDisplayStatus="false"
        />
      </div>
      <div v-else>
        <TargetList
          page="reject"
          :currentPage="page"
          :perPage="perPage"
          :pageCount="pageCount"
          :headers="headersTarget"
          :targetTitle="title"
          :dataList="dataList"
          :total="total"
          :onChangePage="onChangePage"
          :onClickBack="onClickBack"
        />
      </div>
    </div>
  </div>
</template>
<script>
import TabMenu from "@/components/TabMenu";
import RejectList from "@/components/RejectList.vue";
import TransactionList from "@/components/TransactionList";
import SummaryPointList from "@/components/SummaryPointList";
import { reduce } from "lodash";
import TargetList from "@/components/TargetList";
import Utils from "@/mixin";
export default {
  mixins: [Utils],
  name: "Rejected-Transaction",
  components: {
    RejectList,
    TabMenu,
    TargetList,
    TransactionList,
    SummaryPointList,
  },

  data: () => ({
    tap: "transaction",
    page: 1,
    currentStore: 1,
    keyword: "",
    isSearch: false,
    isRejectDetail: false,
    detailFileId: null,
    headersTarget: [
      { text: "No", value: "no", sortable: false, dataType: "number" },
      { text: "เบอร์โทร", value: "phone", sortable: false },
      { text: "SKU", value: "sku", sortable: false },
      { text: "Target Month", value: "targetMonth", sortable: false },
      { text: "Target Year", value: "targetYear", sortable: false },
      {
        text: "Target Amount",
        value: "targetAmount",
        sortable: false,
        dataType: "number",
      },
      {
        text: "Date Rejected",
        value: "dateReject",
        sortable: false,
      },
      { text: "Status", value: "status", sortable: false, dataType: "status" },
    ],

    headersTransaction: [
      { text: "No", value: "no", sortable: false, dataType: "number" },
      { text: "เลขที่เอกสาร", value: "documentNo", sortable: false },
      { text: "เบอร์โทร", value: "phone", sortable: false },
      { text: "SKU", value: "sku", sortable: false },
      {
        text: "จำนวน SKU",
        value: "qty",
        sortable: false,
        dataType: "number",
      },
      {
        text: "Date Rejected",
        value: "dateReject",
        sortable: false,
      },
      { text: "Status", value: "status", sortable: false, dataType: "status" },
    ],
    headersSummary: [
      { text: "ลำดับ", value: "no", sortable: false, class: "col-1" },
      { text: "เบอร์โทรศัพท์", value: "phone", class: "col-2 text-center" },
      { text: "SKU", value: "sku", class: "col-2 text-center" },
      {
        text: "จำนวน SKU",
        value: "qty",
        sortable: false,
        class: "col-2 text-right",
        dataType: "number",
      },
      {
        text: "คะแนนที่ได้",
        value: "point",
        sortable: false,
        class: "col-2 text-right",
        dataType: "number",
      },
      { text: "Date Create", value: "createdAt", class: "col-3 text-center" },
    ],
  }),

  computed: {
    dataList() {
      let result = [];
      if (this.tap === "transaction") {
        const { rejectedTransactions } = this;
        result = reduce(
          rejectedTransactions.data,
          (a, b) => {
            a.push({
              ...b,
            });
            return a;
          },
          []
        );
      } else if (this.tap === "summaryPoint") {
        const { rejectedSummaries } = this;
        result = reduce(
          rejectedSummaries.data,
          (a, b) => {
            a.push({
              ...b,
            });
            return a;
          },
          []
        );
      } else if (this.tap === "target") {
        const { rejectedTargets } = this;
        result = reduce(
          rejectedTargets.data,
          (a, b) => {
            a.push({
              ...b,
            });
            return a;
          },
          []
        );
      }
      return result;
    },
    dataDetailList() {
      let result = [];
      const { rejectedSummaries } = this;
      result = reduce(
        rejectedSummaries.dataDetail,
        (a, b) => {
          b.items.map((a, index) => {
            a.no = index + 1;
          });
          a.push({
            ...b,
          });
          return a;
        },
        []
      );
      return result;
    },

    title() {
      let fileName = "";
      let remark = "";
      let dateReject = "";
      let status = "";
      if (this.tap === "transaction") {
        const { rejectedTransactions } = this;
        fileName = rejectedTransactions.fileName;
        remark = rejectedTransactions.remark;
        dateReject = rejectedTransactions.dateReject;
        status = rejectedTransactions.status;
      } else if (this.tap === "summaryPoint") {
        const { rejectedSummaries } = this;
        console.log(rejectedSummaries);
        fileName = rejectedSummaries.fileName;
        remark = rejectedSummaries.remark;
        dateReject = rejectedSummaries.dateReject;
        status = rejectedSummaries.status;
      } else if (this.tap === "target") {
        const { rejectedTargets } = this;
        fileName = rejectedTargets.fileName;
        remark = rejectedTargets.remark;
        dateReject = rejectedTargets.dateReject;
        status = rejectedTargets.status;
      }
      return {
        fileName: fileName,
        remark: remark,
        dateReject: dateReject,
        status: status,
      };
    },
    total() {
      let total = 0;
      if (this.tap === "transaction") {
        const { rejectedTransactions } = this;
        total = rejectedTransactions.total;
      } else if (this.tap === "summaryPoint") {
        const { rejectedSummaries } = this;
        total = rejectedSummaries.total;
      } else if (this.tap === "target") {
        const { rejectedTargets } = this;
        total = rejectedTargets.total;
      }
      return total;
    },

    perPage() {
      let perPage = 50;
      if (this.tap === "transaction") {
        const { rejectedTransactions } = this;
        perPage = rejectedTransactions.perPage;
      } else if (this.tap === "summaryPoint") {
        const { rejectedSummaries } = this;
        perPage = rejectedSummaries.perPage;
      } else if (this.tap === "target") {
        const { rejectedTargets } = this;
        perPage = rejectedTargets.perPage;
      }
      return perPage;
    },
    pageCount() {
      let pageCount = 1;
      if (this.tap === "transaction") {
        const { rejectedTransactions } = this;
        pageCount = rejectedTransactions.pageCount;
      } else if (this.tap === "summaryPoint") {
        const { rejectedSummaries } = this;
        pageCount = rejectedSummaries.pageCount;
      } else if (this.tap === "target") {
        const { rejectedTargets } = this;
        pageCount = rejectedTargets.pageCount;
      }
      return pageCount;
    },
  },
  methods: {
    onClickChangeTap(value) {
      this.tap = value;
      this.page = 1;
      this.isRejectDetail = false;
      this.isSearch = false;
      this.detailFileId = null;
      this.keyword = "";
      this.getInitialData();
    },
    async getInitialData() {
      let condition = {};
      condition.query = this.keyword ? this.keyword : "";
      condition.limit = this.perPage ? this.perPage : 50;
      condition.page = this.page ? this.page : 1;
      if (this.detailFileId) {
        if (this.tap === "transaction" || this.tap === "summaryPoint") {
          condition.pointUploadId = this.detailFileId;
        } else if (this.tap === "target") {
          condition.targetUploadId = this.detailFileId;
        }
      }

      //paging
      if (this.itemsPerPage) {
        condition.limit = this.itemsPerPage;
      }

      if (this.tap === "transaction") {
        const fetchRejectTransactionData = () => {
          if (!this.isRejectDetail) {
            this.getRejectedTransactionsList(condition);
          } else {
            this.getRejectedTransactions(condition);
          }
        };
        await fetchRejectTransactionData();
      } else if (this.tap === "summaryPoint") {
        const fetchRejectSummariesData = () => {
          if (!this.isRejectDetail) {
            this.getRejectedSummariesList(condition);
          } else {
            this.getRejectedSummariesDetail(condition);
          }
        };
        await fetchRejectSummariesData();
      } else if (this.tap === "target") {
        const fetchRejectTargetData = () => {
          if (!this.isRejectDetail) {
            this.getRejectedTargetsList(condition);
          } else {
            this.getRejectedTargets(condition);
          }
        };

        await fetchRejectTargetData();
      }
    },
    onSearchKey(value) {
      if (value) {
        let isPass = /^[\u0E00-\u0E7FA-Za-z0-9 -]+$/.test(value.trim());
        if (isPass) {
          this.keyword = value.trim();
        } else {
          this.errorSearch = "ไม่สามารถค้นหาได้ เนื่องจากระบุข้อความไม่ถูกต้อง";
          return;
        }
      } else {
        this.keyword = "";
      }
      this.currentStore = 1;
      this.page = 1;
      this.getInitialData();
    },
    onChangePage(value) {
      this.page = value;
      this.getInitialData();
    },
    onViewDetail(value) {
      this.page = 1;
      this.detailFileId = null;
      if (this.tap === "transaction" || this.tap === "summaryPoint") {
        if (value.pointUploadId) {
          this.detailFileId = value.pointUploadId;
        }
      } else if (this.tap === "target") {
        if (value.targetUploadId) {
          this.detailFileId = value.targetUploadId;
        }
      }

      this.isRejectDetail = true;
      this.isSearch = true;
      this.getInitialData();
    },
    onClickBack() {
      this.isRejectDetail = false;
      this.detailFileId = null;
      this.isSearch = false;
      this.page = 1;
      this.getInitialData();
    },
  },

  mounted() {
    window.scrollTo({ top: 0, left: 0 });
    this.getInitialData();
  },
};
</script>
<style lang="scss">
$gray: #d9d9d9;

.reject-container {
  padding: 40px 25px;
  .filter-btn {
    border: 1px solid $gray;
    &.active {
      // color: $green-text;
      background: $gray;
    }
  }
  .v-pagination {
    @media screen and (max-width: 600px) {
      justify-content: center;
    }
    justify-content: flex-start;
  }
}
</style>
