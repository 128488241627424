<template>
  <div>
    <div v-if="isDisplaySubTap">
      <SwitchTab :subTap="subTap" :onClickChangeSubTap="onClickChangeSubTap" />
    </div>
    <div v-if="page === 'reject' && targetTitle.fileName !== ''">
      <v-btn depressed @click="onClickBack">
        <v-icon>mdi-arrow-left</v-icon> Back
      </v-btn>
      <RejectTitle
        :fileName="targetTitle.fileName"
        :status="targetTitle.status"
        :dateReject="targetTitle.dateReject"
        :remark="targetTitle.remark"
      />
    </div>
    <div v-if="dataList != undefined && dataList.length > 0" class="mt-3">
      <TableGroup
        :headers="headers"
        :dataRow="dataList"
        :groupId="'shopId'"
        :groupName="'shopName'"
        :isLoading="isLoading"
        :perPage="perPage"
        :pageCount="tablePagesCount"
      />
      <Paginations
        :page="currentPage"
        :pageCount="tablePagesCount"
        :total="total"
        :itemsPerPage="perPage"
        :itemsPerPageOptions="itemsPerPageOptions"
        :onChangePage="onChangePage"
      />
    </div>
    <div class="d-flex justify-center mt-3" v-else>
      <h3>ไม่พบข้อมูล</h3>
    </div>

    <div
      v-if="
        this.isDisplayConfirm && dataList !== undefined && dataList.length > 0
      "
      class="text-right mr-0 mr-md-4 mt-3"
    >
      <v-btn
        class="mr-5"
        width="150"
        height="40"
        color="error"
        depressed
        @click="onClickReject"
      >
        Reject
      </v-btn>
      <v-btn
        width="150"
        height="40"
        color="primary"
        depressed
        @click="onClickApprove"
      >
        Approve
      </v-btn>
    </div>
    <div v-if="rejectConfirm">
      <ConfirmModal
        v-model="rejectConfirm.status"
        :data="rejectConfirm"
        :enableTextArea="true"
        @confirm="onClickRejectData"
      />
    </div>
    <div v-if="saveConfirm">
      <ConfirmModal
        v-model="saveConfirm.status"
        :data="saveConfirm"
        @confirm="onClickApproveData"
      />
    </div>
  </div>
</template>
<script>
import ConfirmModal from "@/components/ConfirmModal";
import Paginations from "@/components/Paginations";
import TableGroup from "@/components/TableGroup.vue";
import SwitchTab from "@/components/SwitchTab.vue";
import RejectTitle from "@/components/RejectTitle.vue";
import { itemsPerPageOptions } from "@/plugins/common";

export default {
  props: {
    subTap: {
      type: Number,
      default: 1,
    },
    page: {
      type: String,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 50,
    },
    pageCount: {
      type: Number,
      default: 1,
    },

    targetTitle: {
      type: Object,
      default: () => {},
    },
    headers: {
      type: Array,
    },
    dataList: {
      type: Array,
      default: () => [{ data: [] }],
    },
    rejectConfirm: {
      type: Object,
      default: () => {},
    },
    saveConfirm: {
      type: Object,
      default: () => {},
    },
    isDisplaySubTap: {
      type: Boolean,
      default: false,
    },
    isDisplayConfirm: {
      type: Boolean,
      default: false,
    },
    onClickRejectData: {
      type: Function,
      default: () => {},
    },
    onClickApproveData: {
      type: Function,
      default: () => {},
    },
    onClickChangeSubTap: {
      type: Function,
      default: () => {},
    },
    onClickReject: {
      type: Function,
      default: () => {},
    },
    onClickApprove: {
      type: Function,
      default: () => {},
    },
    onChangePage: {
      type: Function,
      default: () => {},
    },
    onClickBack: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    itemsPerPageOptions: [],
  }),
  components: {
    Paginations,
    TableGroup,
    ConfirmModal,
    SwitchTab,
    RejectTitle,
  },
  computed: {
    tablePagesCount: {
      get() {
        return this.pageCount;
      },
      set(value) {
        this.pageCount = value;
      },
    },
  },
  mounted() {
    if (this.isDisplayConfirm) {
      this.isDisplayConfirm = this.subTap !== 1 ? false : true;
    }
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
};
</script>
