import { get } from "lodash";
import { REQUEST_GET, REQUEST_POST, REQUEST_PUT } from "./API_Request";
import { AlertMessage, errorMessage } from "@/plugins/common";
import { GLOBAL_MESSAGE } from "@/plugins/message";
export const state = {
  materialRatiosList: {
    data: [],
    total: 0,
    perPage: 50,
    pageCount: 1,
  },
  manageRatio: {
    error: false,
    errorMessage: "",
  },
};
export const mutations = {
  async GET_MATERIAL_RATIOS_LIST(state, data) {
    data.toggleLoading();
    try {
      const res = await REQUEST_GET("/api/materialratios", data.form);
      if (res?.data?.data && res?.data?.data.length > 0) {
        state.materialRatiosList.data = res?.data?.data;
        state.materialRatiosList.total = res?.data?.total || 0;
        state.materialRatiosList.perPage = res?.data?.perPage || 50;
        state.materialRatiosList.pageCount = res?.data?.lastPage || 1;
      } else {
        state.materialRatiosList = {
          ...state.materialRatiosList,
          data: [],
          total: 0,
          perPage: 50,
          pageCount: 1,
        };
      }

      data.toggleLoading();
      return state;
    } catch (e) {
       data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async SAVE_RATIO(state, data) {
    data.toggleLoading();
    try {
      let body = {
        materialCode: data.form.materialCode,
        operationValue: data.form.operationValue,
        startDate: data.form.startDate,
        endDate: data.form.endDate,
      };
      const res = await REQUEST_POST("/api/materialratios", body);

      if (get(res, "status") === 201) {
        if (res.data?.ok) {
          state.manageRatio.error = false;
          state.manageRatio.errorMessage = "";
          data.toggleLoading();
          const alert = new AlertMessage(
            GLOBAL_MESSAGE.UPLOAD.SUCCESS,
            "ok",
            "success"
          );
          data.toggleAlert(alert);
        } else {
          state.manageRatio.error = true;
          state.manageRatio.errorMessage = res.data?.error;
          data.toggleLoading();

          let alert = new AlertMessage(res.data?.error, "error", "error");
          data.toggleAlert(alert);
        }
        return state;
      }
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.UPLOAD.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
  async CLEAR_RATIO(state, data) {
    data.toggleLoading();
    try {
      state.manageRatio = {
        ...state.manageRatio,
        error: false,
        errorMessage: "",
      };
      data.toggleLoading();
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      return error;
    }
  },
  async UPDATE_RATIO(state, data) {
    data.toggleLoading();
    try {
      
      const res = await REQUEST_PUT(
        "/api/materialratios/" + data.form?.Id,
        data.form
      );
     
      if (res.data?.ok) {
        state.manageRatio.error = false;
        state.manageRatio.errorMessage = "";
        data.toggleLoading();
        const alert = new AlertMessage(
          GLOBAL_MESSAGE.UPLOAD.SUCCESS,
          "ok",
          "success"
        );
        data.toggleAlert(alert);
      } else {
        state.manageRatio.error = true;
        state.manageRatio.errorMessage = res.data?.error;
        data.toggleLoading();

        let alert = new AlertMessage(res.data?.error, "error", "error");
        data.toggleAlert(alert);
      }
      return state;
    } catch (e) {
      data.toggleLoading();
      const error = errorMessage(e);
      let alert = new AlertMessage(GLOBAL_MESSAGE.UPLOAD.ERROR, "error", "error");
      data.toggleAlert(alert);
      return error;
    }
  },
};
export const actions = {
  async getMaterialRatiosList(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.GET_MATERIAL_RATIOS_LIST(state, {
      toggleLoading,
      form,
      toggleAlert,
    });
  },
  async saveRatio(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.SAVE_RATIO(state, { toggleLoading, form, toggleAlert });
  },
  async updateRatio(context, form) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.UPDATE_RATIO(state, { toggleLoading, form, toggleAlert });
  },
  async clearRatio(context) {
    const toggleLoading = () => context.dispatch("toggleLoading");
    const toggleAlert = (alertForm) =>
      context.dispatch("toggleAlertMessage", alertForm);
    return mutations.CLEAR_RATIO(state, { toggleLoading, toggleAlert });
  },
};
export const getters = {
  materialRatiosList(state) {
    return state.materialRatiosList;
  },
  manageRatio(state) {
    return state.manageRatio;
  },
};
export const materialRatios = {
  state,
  mutations,
  actions,
  getters,
};
